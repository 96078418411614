<template>
  <div class="q-pa-md row items-start q-gutter-md justify-start">
      <div class="col-3">
        
      </div>
      <div class="col-6 items-start q-gutter-md">
        <div class="text-h6">Login</div>
        <q-card-section  class="challenge-card row justify-between">
            <q-card-section class="col-md-6">
                <user-login-form @login="login"></user-login-form>
            </q-card-section>
        </q-card-section>
    </div>
  </div>
</template>

<script>
import UserLoginForm from '@/components/UserLoginForm.vue';
import DB from '@/db'
import router from '@/router'
//import { Console } from 'console';
export default {
    components : {
        UserLoginForm
    },
    methods : {
        login(username, password) {
            var msg = this.$q.notify({
                group: false, // required to be updatable
                spinner: true,
                message: 'Logging you in...'
            })
            /*
            axios.post(this.$root.database_host+"/_session", {
                username : username,
                password : password
            }, {withCredentials : true})*/
            var self = this;
            DB.signIn(username,password)
                .then( () => { 
                self.$root.logStatusChanged(true);
                msg({
                    color: 'green-4',
                    textColor: 'white',
                    spinner: false,
                    icon: 'done',
                    message: "That's it, you're in!",
                })
                router.replace("/user/profile")
                
            }).catch((e) => {

                self.$root.logStatusChanged(false);
                if (e.response.status == 401) {
                    msg({
                        color: 'red-5',
                        textColor: 'white',
                        spinner: false,
                        icon: 'warning',
                        message: 'Wrong password or username'
                    })
                } else {
                    msg({
                        color: 'red-5',
                        textColor: 'white',
                        spinner: false,
                        icon: 'warning',
                        message: 'Unable to log you in'
                    })
                }
            });

        }
    },
    mounted() {
        setTimeout( () => {
            if (this.$root.signedIn) router.push("/user/profile");
        },500);
    },
    setup() {
        

    }
}
</script>

<style>

</style>