<template>
  <q-tabs
        v-model="tab"
        class="text-teal"
      >
        <!--<q-tab name="instructions" label="Instructions" @click="go('/instructions')" />
        <q-tab name="code" label="Code" @click="go('/code')" />-->
      </q-tabs>
</template>

<script>
import router from "@/router"
export default {
    data() {
        return {
            tab : "instructions"
        }
    },
    methods : {
        go(url) {
            router.push(url)
        }
    }
}
</script>

<style>

</style>