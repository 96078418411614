<template>
  <q-dialog v-model="showDialog" @hide="onHide" persistent>
    <q-card>
        <q-form ref="form" @submit="onSubmit" class="q-gutter-md">
        <q-card-section>
          <div class="text-h6">Add file</div>
        </q-card-section>
        
        <q-card-section class="q-pt-none">
            <q-input
                filled
                v-model="filename"
                label="Filename"
                :rules="[ val=> val && val.length> 0 || 'Please fill in a username', val=> hasValidType(val) || 'Missing a valid extension']"></q-input>
            <!--<q-select filled v-model="type" :options="types" label="Type" />-->
        </q-card-section>    

    <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn flat label="Create file" type="submit" color="primary" />
    </q-card-actions>
    </q-form>
    </q-card>
</q-dialog>
</template>

<script>
import Environment from "@/environments";
//import Workspace from "@/workspace"

const types = [];
const environments = Environment.getList();
for (var i in environments) {
    types.push( {label: environments[i].title, value : environments[i].key });
}

export default {
    props: {
        show : Boolean
    },
    data() {
        return {
            showDialog : false,
            filename : "",
            type : "",
            types : types
        }
    },
    watch : {
        show() {
            this.showDialog = this.show;
        }
    },
    methods : {
        onSubmit() {
            let type = Environment.fileExt2Env(this.filename)
            this.$emit('create', this.filename, type)
            this.showDialog = false;
        },
        onHide() {
            this.type = "";
            this.filename = "";
            this.$emit("hide");
        },
        hasValidType(filename) {
            let segs = filename.split(".");
            if (segs.length < 2) return false;
            let type = Environment.fileExt2Env(
                segs[segs.length-1] //Attempt to convert extension to a type
            );
            if (type == null) return false;
            return true;
        },
        fileExists(filename) {
            console.log(filename)
        }
    }
}
</script>

<style>

</style>