<template>
  <q-form
    @submit="onSubmit"
    class="q-gutter-md">
    <q-input
        filled
        v-model="username"
        label="Username"
        lazy-rules
        :rules="[ val=> val && val.length> 0 || 'Please fill in a username']"></q-input>
    <q-input
        filled
        v-model="password"
        type="password"
        label="Password"
        lazy-rules
        :rules="[ val=> val && val.length> 0 || 'Please fill in a password']"></q-input>

      <div>
          <q-btn label="Login" type="submit" color="primary"></q-btn>
          <q-btn label="Cancel" type="button" flat class="q-ml-sm" @click="cancel"></q-btn>
          </div>
  </q-form>
</template>

<script>
import { sha256 } from '@/util/digest'
export default {
    data() {
        return {
            username : "",
            password : ""
        }
    },
    methods : {
        async onSubmit() {
            var hashed = await sha256(this.password+this.username.toString()+document.location.hostname);
            this.$emit("login", this.username.toString(), hashed)

            console.log("Good job")
        },
        cancel() {
            this.$emit("cancel");
        }
    }
}
</script>

<style>

</style>