import assert from '@/assert'
import Workspace from '@/workspace'
import Challenge from '@/challenges';
import { createUUID } from '@/utils';
const INVALID_WORKSPACE = 1
const INVALID_CHALLENGE_NAME = 2
const INVALID_WORKSPACE_MODE = 3
const INVALID_WORKSPACE_ENV = 4
//const INTRODUCTION_TEXT_TOO_SHORT = 5
//const INSTRUCTION_TEXT_TOO_SHORT = 6
const INVALID_WORKSPACE_NO_INTRODUCTION = 7
//const INVALID_WORKSPACE_NO_INSTRUCTIONS = 8;
const INVALID_WORKSPACE_CHALLENGE = 9
const CHALLENGE_MISSING_NAME = 1;
const CHALLENGE_MISSING_INTRODUCTION = 2;
const CHALLENGE_MISSING_INSTRUCTIONS = 3;
const CHALLENGE_MISSING_ENVIRONMENT = 4;
const CHALLENGE_MISSING_DIFFICULTY = 5;
//const CHALLENGE_MISSING_START_CODE = 6;
const CHALLENGE_MISSING_UNIT_TESTS = 7;
const CHALLENGE_IS_NO_OBJECT = 8;
const CHALLENGE_MISSING_ID = 9;



const new_challenge = () => {return {
    uuid : '221fc387-11b9-451a-8406-9b429ec665b2',
    name : "The simple sum method",
    introduction : "Create a method that returns the sum of two numbers! The method accepts two int arguments, adds them together and returns the result",
    instructions : "Create a method that returns the sum of two numbers! The method accepts two int arguments, adds them together and returns the result",
    environment : "dotnet6",
    difficulty : 1,
    start_code : {
        "Program.cs" : `class Program
{
public static int Sum(int a, int b)
{

}
}`
    },
    cursor_position : [
        "Program.cs", 4, 8
    ],
    unit_tests : `Assert.Equal(20, Program.Sum(10,10));
`
    
}}

const tests = {
    async validateChallenge_missingId() {
        const challenge = new_challenge();
        let validates = Challenge.validateChallenge(challenge)
            .catch( () => { assert.True(false, "Challenge should validate correctly")});

        challenge.uuid="" 
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_ID, e.code, "Challenge uuid is missing or incorrect")})

        assert.HasNoValue(validates);
    },
    async validateChallenge_missingEnvironment() {
        const challenge = new_challenge();
        let validates = Challenge.validateChallenge(challenge)
            .catch( () => { assert.True(false, "Challenge should validate correctly")});

        challenge.environment="" 
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_ENVIRONMENT, e.code, "Challenge environment is missing or incorrect")})

        assert.HasNoValue(validates);
    },
    async validateChallenge_missingIntro() {
        const challenge = new_challenge();
        let validates = Challenge.validateChallenge(challenge)
            .catch( () => { assert.True(false, "Challenge should validate correctly")});

        challenge.introduction = "Too short"
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_INTRODUCTION, e.code, "Challenge introduction is missing or too short")})

        assert.HasNoValue(validates);
    },

    async validateChallenge_missingInstructions() {
        const challenge = new_challenge();
        let validates = Challenge.validateChallenge(challenge)
            .catch( () => { assert.True(false, "Challenge should validate correctly")});

        challenge.instructions = "Too short"
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_INSTRUCTIONS, e.code, "Challenge instuctions are missing or too short")})

        assert.HasNoValue(validates);
    },

    async validateChallenge_missingDifficulty() {
        const challenge = new_challenge();
        let validates = false;

        delete challenge.difficulty
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_DIFFICULTY, e.code, "Challenge difficulty are missing or invalid")})

        challenge.difficulty = -1
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_DIFFICULTY, e.code, "Challenge difficulty are missing or invalid")})
        assert.HasNoValue(validates);

        challenge.difficulty = 6
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_DIFFICULTY, e.code, "Challenge difficulty are missing or invalid")})
        assert.HasNoValue(validates);

        challenge.difficulty = 4
        validates = await Challenge.validateChallenge(challenge)
            .catch( () => { assert.True(false, "Challenge should validate correctly")});
        
        assert.True(validates, "Challenge should validate as difficulty is 4")
    },

    async validateChallenge_missingUnitTests() {
        const challenge = new_challenge();
        let validates = Challenge.validateChallenge(challenge)
            .catch( () => { assert.True(false, "Challenge should validate correctly")});

        challenge.unit_tests = ''
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_UNIT_TESTS, e.code, "Challenge is missing unit tests")})

        assert.HasNoValue(validates);
    },

    async validateChallenge_challengeIsNoObject() {
        let challenge = undefined;

        let validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_IS_NO_OBJECT, e.code, "Challenge is not an object")})

        assert.HasNoValue(validates);
    },
    async validateWorkspaceToChallenge_invalidWorkspace() {
        const workspace = {}
        const result = await Challenge.validateWorkspaceToChallenge(workspace). catch(error => {
            assert.Equal(INVALID_WORKSPACE,error.err);
        })
        assert.HasNoValue(result)

    },
    async validateWorkspaceToChallenge_invalidWorkspaceName() {
        const name = "1234";
        
        const workspace = await Workspace.createWorkspace(name, "challengeeditor", "dotnet6", {challenge : createUUID()});
        
        assert.Equal(name, workspace.name);

        const result = await Challenge.validateWorkspaceToChallenge(workspace).catch(error => {
            assert.Equal(INVALID_CHALLENGE_NAME, error.code);
        })
        assert.HasNoValue(result)
    },
    async validateWorkspaceToChallenge_invalidWorkspaceType() {
        const name = "This is a good challenge";
        const workspace = await Workspace.createWorkspace(name, "sandbox", "dotnet6");
        workspace.challenge = createUUID()
        assert.Equal(name, workspace.name);

        const result = await Challenge.validateWorkspaceToChallenge(workspace).catch(error => {
            assert.Equal(INVALID_WORKSPACE_MODE, error.code);
        })
        assert.HasNoValue(result)
    },
    async validateWorkspaceToChallenge_envDoesNotSupportChallenge() {
        const name = "A nice workspace but it aint' for challenges";
        const workspace = await Workspace.createWorkspace(name, "challengeeditor", "python3",);
        
        workspace.challenge = createUUID()
        assert.Equal(name, workspace.name);

        const result = await Challenge.validateWorkspaceToChallenge(workspace).catch(error => {
            assert.Equal(INVALID_WORKSPACE_ENV, error.code);
        })
        assert.HasNoValue(result)
    },
    async validateWorkspaceToChallenge_missingChallengeId() {
        const name = "A nice workspace but it has no challenge id";
        const workspace = await Workspace.createWorkspace(name, "challengeeditor", "dotnet6");
        assert.Equal(name, workspace.name);

        const result = await Challenge.validateWorkspaceToChallenge(workspace).catch(error => {
            assert.Equal(INVALID_WORKSPACE_CHALLENGE, error.code);
        })
        assert.HasNoValue(result)
    },

    async validateWorkspaceToChallenge_missingFiles() {
        const name = "A nice workspace but is missing files";
        const workspace = await Workspace.createWorkspace(name, "challengeeditor", "dotnet6");
        workspace.challenge = createUUID()
        assert.Equal(name, workspace.name);

        const emptyResult = await Challenge.validateWorkspaceToChallenge(workspace).catch(error => {
            assert.Equal(INVALID_WORKSPACE_NO_INTRODUCTION, error.code);
        })

        assert.HasNoValue(emptyResult)

        await Workspace.addDefaultWorkspaceFiles(workspace);
        Workspace.fileContent("Create a method that returns the sum of two numbers! The method accepts two int arguments, adds them together and returns the result",
            "Introduction.md",
            workspace)
        Workspace.fileContent("Create a method that returns the sum of two numbers! The method accepts two int arguments, adds them together and returns the result",
            "Instructions.md",
            workspace)

        const resultOk = await Challenge.validateWorkspaceToChallenge(workspace).catch(error => {
            assert.HasNoValue(error)
            console.error(error)
        })

        assert.True(resultOk, "A workspace should have been validated to challenge")
    },

    async validateChallenge_missingName() 
    {
        const challenge = new_challenge();
        let validates = Challenge.validateChallenge(challenge)
            .catch( () => { assert.True(false, "Challenge should validate correctly")});

        challenge.name = "Too short"
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_NAME, e.code, "Challenge name is too short")})

        delete challenge.name;
        validates = await Challenge.validateChallenge(challenge)
            .catch( (e) => { assert.Equal(CHALLENGE_MISSING_NAME, e.code, "Challenge is missing name")})
        
        assert.HasNoValue(validates);
    },

    async constructChallengeFromWorkspace() {
        const DIFFICULTY = 3;
        const ws = await Workspace.createWorkspace("The simple sum method", "challengeeditor", "dotnet6").catch(
            (err) => {
                assert.Equal(1, err.err)
            }
        )
        
        ws.challenge = createUUID();
        ws.meta.author = "Simon Hoxer"
        Workspace.setSetting("level",DIFFICULTY,ws);
        await Workspace.addDefaultWorkspaceFiles(ws);
        Workspace.fileContent(`class Program
        {
            public static int Sum(int a, int b)
            {
            
            }
        }`,
        "Program.cs",
        ws)
        Workspace.fileContent("Create a method that returns the sum of two numbers! The method accepts two int arguments, adds them together and returns the result",
            "Introduction.md",
            ws)
        Workspace.fileContent("Create a method that returns the sum of two numbers! The method accepts two int arguments, adds them together and returns the result",
            "Instructions.md",
            ws)
        Workspace.fileContent("Assert.Equal(0, Program.Sum(2,-2))",
            "Tests.cs",
            ws)

        let challenge = await Challenge.createChallengeFromWorkspace(ws);
        let validates = await Challenge.validateChallenge(challenge);
        //Make sure the author is added to challenge
        assert.Equal(ws.meta.author, challenge.meta.author, "The workspace author should be copied to the challenge");

        assert.True(validates);
    }

}

export default tests;