<!--
Moderater content - mounted() redirects back if user is not privileged
-->
<template>
    <div class="q-pa-md row items-start q-gutter-md">
    <div class="col-md-6">
        <div class="text-h6">Pending workspaces</div>
        <q-list bordered separator>
            <q-item clickable v-ripple 
                    v-for="item in pending"
                    :key="item._id">
                <q-item-section>
                    <q-item-label>{{item.name}}</q-item-label>
                    <q-item-label caption>{{item.environment}}</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <div class="text-grey-8 q-gutter-xs">
                    <!--<q-btn class="gt-xs" size="16px" flat dense round icon="delete" @click="remove(ws.doc)" />-->
                    <q-btn size="16px" flat dense round icon="edit" @click="edit(item)" />
                    <q-btn size="16px" color="primary" flat dense round icon="visibility" @click="openChallenge(item)" />
                    
                    <!--<q-btn size="12px" flat dense round icon="more_vert" />-->
                    </div>
                </q-item-section>
            </q-item>
        </q-list>
    </div>
    <div class="col-md-12" v-if="workspace != null">
        <code-ide   :workspace="workspace"
                    :environment="environment">
        </code-ide>
        <q-button>Approve</q-button>
    </div>
    
  </div>
  <edit-workspace-dialog :showing="showEditWorkspace" :workspace="workspace"
    @saveWorkspace="saveWorkspace"
    @hide="onHide">
  </edit-workspace-dialog>
</template>

<script>
import DB from '@/db'
import router from '@/router'
import Workspace from '@/workspace'
import CodeIde from '@/components/code/CodeIde'
import EditWorkspaceDialog from '@/components/workspace/EditWorkspaceDialog'
import { ref } from "vue";
export default {
    components : { EditWorkspaceDialog,CodeIde},
    data() {
        return {
            workspace : {},
            pending : []
        }
    },
    async mounted() {
        if (!DB.userHasRole('moderator')) {
            this.$root.failed("You're not logged in as a moderator", this)
            router.push(`/user/profile`);
        }
        this.pending = await DB.remote_getPendingChallenges();
        
        
    },
    methods : {
        async openChallenge(item) {
            delete item._id
            this.workspace = await Workspace.constructWorkspaceFromChallenge(item);
            this.environment = this.workspace.environment    
            return item;
        },
        edit(ws) {
            this.workspace = ws;
            this.showEditWorkspace = true
        },
        onHide() {
            this.showEditWorkspace = false
        },
        saveWorkspace(what) {
            console.log("Saving workspace")
            console.log(what)
        }
    },
    setup() {
        const environment = ref("");
        return {environment}
    }
}
</script>

<style>

</style>