<template>
{{showNewWorkspace}}
    <edit-workspace-dialog :showing="showNewWorkspace"
        @hide="showNewWorkspace=false">
    </edit-workspace-dialog>
    <q-btn @click="showNewWorkspace=true">New workspace</q-btn>

{{showEditWorkspace}}
    <edit-workspace-dialog :showing="showEditWorkspace"
        :workspace="workspace"
        @hide="showEditWorkspace=false">
    </edit-workspace-dialog>
    <q-btn @click="showEditWorkspace=true">Edit workspace</q-btn>
    
</template>

<script>
import workspace from "@/workspace"
import EditWorkspaceDialog from "@/components/workspace/EditWorkspaceDialog"
export default {
    components : { EditWorkspaceDialog },
    data() {
        return {
            workspace : null,
            showNewWorkspace : false,
            showEditWorkspace : false,
        }
    },
    methods : {
        workspaceSaved(name, environment, mode) {
            console.log(`Creating workspace: ${name}-${environment}-${mode}`)
        }
    },
    async mounted() {
        await workspace.loadLastWorkspace("python3", "sandbox");
        
        this.workspace = workspace.current()

    }
}
</script>

<style>

</style>