<template>
<q-card-section>
    Difficulty
    <q-knob
      v-model.number="level"
      :min="1"
      :max="5"
      show-value
      :thickness="0.3"
      color="blue-grey-7"
      class="q-ma-md"
      track-color="grey-8"
      @change="changed(level)"
      filled
    />{{level_desc}}
</q-card-section>
</template>

<script>
export default {
    props : {
        workspace : Object
    },
    data() {
        return {
            level : this.workspace.settings.level,
        }
    },
    watch : {
        workspace() {
            this.workspace.settings.level
        }
    },
    computed : {
        level_desc() {
            switch(this.level) {
                case 1: return "Novice"
                case 2: return "Intermediate"
                case 3: return "Advanced"
                case 4: return "Experienced"
                case 5: return "Expert"
                default: return "Novice"
            }
        }
    },
    methods : {
        changed() {
            this.$emit("changed", { level : this.level });
        }
    },
    emits : ["changed"]
}
</script>

<style>

</style>