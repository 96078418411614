<template>
  <div class="q-pa-md row items-start q-gutter-md">
    <div class="col-md-6">
        <div class="text-h6">Select a language</div>
        <div class="q-pa-md row items-start q-gutter-md">
            <div v-if="environments.length == 0">There's no languages for {{mode}} </div>
            <q-btn v-else class="techcard bg-grey-9" push
                v-for="env in environments"
                :key="env.key"
                @click="go(env.key)">
                <div class="row items-center no-wrap envbtn">
                    <img :src="imgsrc(env.icon64)" >
                    <div class="text-center">
                        {{env.title}}
                    </div>
                </div>
            </q-btn>
        </div>
    </div>
    <div class="col-md-6">
    </div>
  </div>
</template>

<script>
import Environment from '@/environments'
import { ref } from 'vue'
import router from '@/router'
import { headerPaneStore } from '@/stores/header'

let url2mode = {
    "challenges" : "challenge",
    "sandbox" : "sandbox"
}
export default {
    watch:{
        $route (){
            this.loadEnvironments();
        }
    } ,
    methods : {
        imgsrc(file) {
            return 'assets/tech/'+file;
        },
        go(url) {
            router.push(`/${url}/${this.mode}`);
        },
        loadEnvironments() {
            var dirs = document.location.pathname.split("/");
            if (dirs.count == 0)
                throw "Unable to get mode from pathname: "+document.location.pathname;
            if (url2mode[dirs[1]]) {
                this.mode = url2mode[dirs[1]];
            
                this.environments = Environment.getList(this.mode.toString());
            }
        }
    },
    mounted() {
        this.loadEnvironments()
    },
    setup()
    {
        const headerPane = headerPaneStore();
        headerPane.visible = true;
        const environments = ref([]);
        const mode = ref("");
        const envcount = ref(0);

        return { environments, mode, envcount}
    }
}
</script>

<style>
.techcard img {
    height: 64px;
    width: 64px;
    
    margin-right:16px;
    margin-top:8px;
    margin-bottom:8px;
}
.envbtn {
    min-width: 160px;
}
</style>