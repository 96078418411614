<template>
  <code-tabs></code-tabs>
  Instructions
</template>

<script>
import CodeTabs from "@/components/CodeTabs.vue"
export default {
  components : {
    CodeTabs
  }
}
</script>

<style>

</style>