<template>
  <q-layout view="lHh Lpr lFf">
    <header-pane v-if="headerPane.visible" 
      :signedIn="signedIn"
      @signout="signout"
      @toggleLeftPane="leftDrawerOpen = !leftDrawerOpen"></header-pane>
    <q-drawer
      v-if="headerPane.visible"
      v-model="leftDrawerOpen"
      show-if-above

      bordered
    >
      <q-list>
        <q-item-label header>Write code</q-item-label>
        <q-item clickable tag="a" target="_blank" @click="go('/challenges')">

          <q-item-section avatar>
            <q-icon name="school" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Learn</q-item-label>
            <q-item-label caption>Using home-grown challenges</q-item-label>
          </q-item-section>

        </q-item>

        <q-item clickable tag="a" target="_blank" @click="go('/sandbox')">

          <q-item-section avatar>
            <q-icon name="science" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Experiment</q-item-label>
            <q-item-label caption>The sandbox experience</q-item-label>
          </q-item-section>
        </q-item> 
        <q-item clickable tag="a" target="_blank" @click="go('/moderation')" v-if="isModerator">

          <q-item-section avatar >
            <q-icon name="shield" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Moderation</q-item-label>
            <q-item-label caption>Maintain challenges</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
    
    <q-page-container>
        <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import { useQuasar } from 'quasar'
import router from './router'
import HeaderPane from './components/HeaderPane.vue';
import { headerPaneStore } from '@/stores/header'
import {evaluate, storage, backend} from '@/services';
import { MessageBox } from '@/utils'
import DB from '@/db'

export default {
    components : {
        HeaderPane
},
  data() {
    return {
      database_host : "https://sofa.hoxer.net:6984",
      signedIn : false,
      q : useQuasar()
    }
  },
  computed : {
    isModerator() {
      if (this.signedIn) {
        return DB.userHasRole('moderator') || DB.userHasRole('admin')
      }
      return false;
    }
  },
    methods : {
      go(url)  {
        router.push(url);
      },
      onItemClick(A) {
        console.log(A)
      },
      signout() {
        var self = this;
        DB.signOut().then( () => {
          self.logStatusChanged(false);
          router.replace("/user/signin")
        })
      },
      logStatusChanged(val) {
        if (this.signedIn == val) return;
        console.log("New log status "+val)

        if (val)
        {
          console.log("DB Sync is disabled");
          //DB.sync();
        }
        this.signedIn = val
      },
      success(msg) { MessageBox.success(msg, this); },
      failed(msg) { MessageBox.failed(msg, this); },
      warn(msg) { MessageBox.warning(msg, this); },
    },
    async mounted() {
        
        //DB.onLoginStateChanged(this.loginStateChanged);
        var self = this;
        this.signedIn = await DB.checkUserStatus( (result) => {
          self.logStatusChanged(result.ok || false)
          
        });
    },
    setup () {
      const $q = useQuasar();
      const headerPane = headerPaneStore();
      $q.dark.set(true);


      evaluate.defaults.baseURL = process.env.VUE_APP_EVALUATION_URL
      backend.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
      storage.defaults.baseURL = process.env.VUE_APP_STORAGE_URL

        return {
            leftDrawerOpen: ref(false),
            headerPane
        }
    }
}

</script>

<style>

</style>