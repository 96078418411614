const PROP_NOT_ALLOWED = "PROP_NOT_ALLOWED"
const PROP_REQUIRED = "PROP_REQUIRED"
const INVALID_VALUE_TYPE = "INVALID_VALUE_TYPE"
const INVALID_VALUE = "INVALID_VALUE"
const VALUE_LENGTH_TOO_SHORT = "VALUE_LENGTH_TOO_SHORT";
const VALUE_LENGTH_TOO_LONG = "VALUE_LENGTH_TOO_LONG"
const VALUE_NO_MATCH_REGEXP = "VALUE_NO_MATCH_REGEXP"
const INVALID_ENTITY = "INVALID_ENTITY"
const UNKNOWN_PROPERTY = "UNKNOWN_PROPERTY"
function err(code, msg, path) {
    
    msg += path != "" ? ` Path is ${path}` : ``
    return {code:code,message : msg}
}

const entity_schema = {}

function reset() {
    for (let k in entity_schema) { delete entity_schema[k] }
}

function trim(str, char) {
    while (str.substring(0,1) == char) {
        str = str.substring(1)
    }
    while (str.substring(str.length-1) == char) {
        str = str.substring(0,str.length-1)
    }
    return str;
}

function evaluate_if(condition,entity, path) {
    let equal = condition.split("=");
    let a = equal[0];
    let b = equal[1];
    if (trim(a, "'") != a) a = trim(a, "'")
    else if (typeof(entity[a]) !== "undefined") a = entity[a]
    else throw err(UNKNOWN_PROPERTY, `Unknown left hand side identifier: ${a}`, path)

    if (trim(b, "'") != b) b = trim(b,"'")
    else if (typeof(entity[b]) !== "undefined") b = entity[b]
    else throw err(UNKNOWN_PROPERTY, `Unknown right hand side identifier: ${b}`, path)

    return a === b
}

function validate(data, schema, path="") {
    if (path == "") {
        reset();
        if (Array.isArray(schema.schemas)) {
            for (let i in schema.schemas) {
                if (typeof(schema.schemas[i].entity) === "string") {
                    entity_schema[schema.schemas[i].entity] = schema.schemas[i];
                }
            }
        }
        if (typeof(schema.document) == "object")
            schema = schema.document
        
    } else 
        path = path+".";

    //if entity property is set in schema, go find it
    if (typeof(schema.entity) === "string") {
        schema = get_entity_schema(schema.entity, path)
    }
    
    

    if (Array.isArray(data)) {
        let validates = true;
        for (let i in data) {
            validates = validates && validate(data[i], schema, path+ `[${i}]`)
        }
        return validates;
    } else {

        validate_required_properties(data, schema, path);
        validate_properties_are_allowed(data, schema, path);
        
        validate_values_are_allowed(data, schema, path);
    }

    if (typeof (schema.allowed_properties) == "undefined")
        return true;

    
    

    for (let property in data) {
        //If all properties are allowed by * (star notation) we will apply 
          // properties from data to the schema, so they are formally allowed
        //if (schema.allowed_properties["*"] !== undefined)
        //    s
        
        //Validate type
        validate_property_type(property, data, schema.allowed_properties, path)

        //Validate entity (if set) 
        validate_property_entity(property, data, schema.allowed_properties, path)

        //Validate value is allowed
        validate_property_value_allowed(property, data, schema.allowed_properties, path)

        //Validate char length
        validate_property_length(property, data, schema.allowed_properties, path)

        //Validate regexp
        validate_property_regexp(property, data, schema.allowed_properties, path)

        //Validate recursive
        if (typeof (schema.allowed_properties[property]) === "object") {
            let entity_schema
            if (typeof (schema.allowed_properties[property].entity) === "string") {
                entity_schema = get_entity_schema(schema.allowed_properties[property].entity, path)
            } else if (typeof (schema.allowed_properties[property].schema) === "object") {
                entity_schema = schema.allowed_properties[property].schema
            } else {
                continue //No need to validate recursively
            }

            if (Array.isArray(data[property])) {
                for (let i in data[property]) {
                    validate(data[property][i], entity_schema, path+ `${property}[${i}]`)
                }
            } else if(typeof(data[property]) == "object") {
                validate(data[property], entity_schema, path+property)
            }
        }
        /*
        if (typeof(data[property]) == "object" && Array.isArray(data[property]) === false
            && typeof (schema.allowed_properties[property]) === "object"
            && typeof (schema.allowed_properties[property].schema) === "object"
            ) {
            //const sub_schema
            validate(data[property], schema.allowed_properties[property].schema, path+property)
        } else if (Array.isArray(data[property]) === true
            && typeof (schema.allowed_properties[property]) === "object"
            && typeof (schema.allowed_properties[property].entity) === "string") {
            for (let i in data[property]) {
                validate(data[property][i], get_entity_schema(schema.allowed_properties[property].entity, path), path+ `${property}[${i}]`)
            }
        }*/
    }
}

function validate_property_entity(property, data, schema, path) {
    let schema_property = property
    if (typeof(schema["*"]) !== "undefined" && typeof(schema[property]) === "undefined")
        schema[property] = get_entity_schema(schema["*"].entity);

    if (typeof(schema[schema_property].entity) !== "string") return;

    validate(data[property], get_entity_schema(schema[schema_property].entity, path), path+ `${property}`)
            
}

function get_entity_schema(entity, path) {
    if (entity_schema[entity])
        return entity_schema[entity];
    else throw err(INVALID_ENTITY, `Unrecognized entity '${entity}'.`, path)

}

function validate_property_value_allowed(property, data, schema, path) {
    if (typeof(schema["*"]) !== "undefined" && typeof(schema[property]) === "undefined")
        schema[property] = get_entity_schema(schema["*"].entity);

    if (typeof(schema[property].allowed_values) !== "object"
        || data[property] === null) return false;
    
    if (schema[property].allowed_values.indexOf(data[property]) === -1)
        throw err(INVALID_VALUE,`Value '${data[property]}' of property '${property}' is not allowed.`, path)
         
}

function validate_values_are_allowed(data, schema, path) {
    if (!Array.isArray(schema.allowed_values)) return;
    for (let i in data) {
        if (schema.allowed_values.indexOf(data[i]) === -1)
            throw err(INVALID_VALUE,`Value '${data[i]}' is not allowed.`, path)
         
    }
}

function validate_property_regexp(property, data, schema, path) {
    if (typeof(schema["*"]) !== "undefined" && typeof(schema[property]) === "undefined")
        schema[property] = get_entity_schema(schema["*"].entity);
    const tpl = schema[property].regex;
    if (typeof(tpl) === "undefined")
        return true;
    
    const regexp = new RegExp(tpl);
    if (regexp.test(data[property].toString()) === false) {
        throw err(VALUE_NO_MATCH_REGEXP, `Value ${data[property]} does not match pattern ${tpl}`, path);
    }
}



function validate_property_length(property, data, schema, path) {
    if (typeof(schema["*"]) !== "undefined" && typeof(schema[property]) === "undefined")
        schema[property] = get_entity_schema(schema["*"].entity);

    const min_length = schema[property].min_length || 0;
    const max_length = schema[property].max_length || Number.MAX_VALUE
    
    if (data[property] == null) return;

    const len = data[property].toString().length;
    if (len < min_length)
        throw err(VALUE_LENGTH_TOO_SHORT, `${property} value must be at least ${min_length} characters. Is ${len} characters long.`,path)
    if (len > max_length)
        throw err(VALUE_LENGTH_TOO_LONG, `${property} value too long, max is ${max_length} characters. Is ${len} characters long.`,path)
}

function validate_property_type(property, data, schema, path) {
    let schema_property = schema[property]
    if (typeof(schema["*"]) !== "undefined" && typeof(schema[property]) === "undefined")
        schema_property = get_entity_schema(schema["*"].entity);

    if (typeof(schema_property.null) === "boolean"
        && schema_property.null === true
        && data[property] == null)
        return true;

    let data_type = data[property] === null ? "null" : typeof(data[property]);
    if (data_type == "object" && Array.isArray(data[property])) {
        data_type = "array"
    }
    if (typeof(schema_property.type) === "string"
        && data_type != schema_property.type)
        throw err(INVALID_VALUE_TYPE, `property '${property}' has invalid type: ${data_type}. Must be ${schema[property].type}.`, path)
}

function validate_required_properties(data, schema, path) {
    for (var schema_prop in schema.allowed_properties) {
        const p = schema.allowed_properties[schema_prop];

        if (typeof p["required-if"] !== "undefined" 
            && typeof p.required !== "undefined") {
            throw err(PROP_NOT_ALLOWED, `Cannot have both required-if and required in same property`)
        }

        //Check required-if
        let requiredif = false
        if (typeof p["required-if"] !== "undefined") {
            if (typeof p["required-if"] !== "string")
                throw err(INVALID_VALUE, `Schema property required-if has invalid value type. Must be string`)

            requiredif = evaluate_if(p["required-if"], data, path);
        }

        let required = p.required && p.required === true;
        
        //Check static required=true
        if ( (required || requiredif)
            && typeof (data[schema_prop]) === "undefined") {
            throw err(PROP_REQUIRED, `property '${schema_prop}'`, path);
        }
    }
}


function validate_properties_are_allowed(data, schema, path) {
    //validate if allowed_properties are present
    if (typeof(schema.allowed_properties) === "object") {
        for (var data_prop in data) {
            let prop_allowed = typeof(schema.allowed_properties[data_prop]) !== "undefined"
                || (typeof(schema.allowed_properties["*"]) !== "undefined"
                    && (typeof(schema.disallowed_properties) == "undefined"
                        || typeof(schema.disallowed_properties[data_prop]) == "undefined" ));
            
            if (!prop_allowed) throw err(PROP_NOT_ALLOWED,  `property '${data_prop}'`, path)
        }
    } else if (typeof(schema.disallowed_properties) === "object") {
        
        for (let data_prop in data) {
            let prop_allowed = schema.disallowed_properties.indexOf(data_prop) === -1;
            
            if (!prop_allowed) throw err(PROP_NOT_ALLOWED, `property '${data_prop}'`, path)
        }
    }
}


exports.validate = validate;
