<template>
  <code-tabs></code-tabs>
  <div class="q-pa-md row items-start q-gutter-md">

      <div class="col">
          
        <code-editor
          @runState="onRunStateChanged" 
            :workspace="workspace" 
            :mode="'challengeeditor'"
            :options="options"></code-editor>
            <div v-if="workspace">{{workspace._rev}}</div>
      </div>
      <div class="col">
        <code-output :output="output" :state="state"></code-output>
      </div>
      
  </div>
  <!--<div v-if="workspace != null">Difficulty: {{workspace.settings.level}}</div>-->
  <q-btn v-if="isRemote" label="Resubmit challenge" flat icon="cloud_upload" @click="saveChallengeToCloud" />
  <q-btn v-else label="Upload challenge" flat icon="upload" @click="saveChallengeToCloud" />
  
  
</template>

<script>
import CodeEditor from "@/components/CodeEditor.vue"
import CodeTabs from "@/components/CodeTabs.vue"
import CodeOutput from "@/components/CodeOutput.vue"

import { headerPaneStore } from '@/stores/header'
import { ref } from 'vue'
import DB from "@/db"
import Workspace from '@/workspace'
import Environment from "@/environments";
import { evaluate } from '@/services'
import Challenge from '@/challenges'

export default {
  components : {
    CodeTabs,
    CodeEditor,CodeOutput
  },
  data() {
    return {
      output : "",
      state : -1,
      workspace : null,
      environment : "",
      options : {
        testfile : ""
      }
    }
  },
  watch : {
    workspace() {
      const env = Environment.get(this.workspace.environment);
      console.log("Setting "+env.testfile)
      this.options.testfile = env.testfile;
    }
  },
  methods : 
  {
    onRunStateChanged(state, result) {
      this.state = state;
      switch (state) 
      {
        case 0: 
          this.output = "Error: "+result;
          break;
        case 1:
          //this.output = "Running";
          break;
        case 2: 
          this.output = result;
          break;
      }
    },
    async loadWorkspaceFromId(challenge_id) {
      const result = await DB.find({
        selector: {challenge: challenge_id},
        fields: ['name']
      });
      if (result && result.docs && result.docs.length > 0) {
        const ws = await Workspace.load(result.docs[0].name);
        
        return ws;
      }
      console.error("Unable to find challenge workspace with id "+challenge_id);
      return null;
    },
    async saveChallengeToCloud() {
      let workspace = Workspace.current();
      console.log("Saving to cloud")
      const err = (e) => {
        if (e.msg) {
          this.$root.failed(e.msg)
        } else if (e.response) {
          this.$root.failed(e.response.data)
        } else {
          console.log(e);
          this.$root.failed(e)
        }
      }
      let challenge = await Challenge.createChallengeFromWorkspace(workspace).catch(err)
      challenge.state = 1;
      if (challenge)
        evaluate.post("challenges/save", challenge).then( (response) => {
          //if (!response || !response.data || !response.data.id)
          response;
          this.$root.success("Saved")
        }).catch(err);
    }
  },
  async mounted() {
    const challenge_id = this.$route.params.challenge

    var path = this.$route.path;
    this.isRemote = path.indexOf("/remote/") !== -1;
    
    const headerPane = headerPaneStore();
    headerPane.show();
    
    var url = "/challenge/"
      + this.$route.params.challenge

    if (this.isRemote) {
      let response = {};
      try {
        response = await evaluate.get(url);
      } catch {
        console.log(`Unable to fetch challenge ${challenge_id}`)
        return;
      }
      if (response.data._id != challenge_id){
        throw `Received incorrect challenge from service. Received '${response.data._id}' but expected '${challenge_id}'`;
      }
      const challenge = response.data;
      
      if (!DB.userHasRole("moderator") || DB.credentials.username !== challenge.meta.author) {
        this.qualifiedUser = false;
        this.$root.failed("Unable to edit this challenge", this)
        this.$router.push(`/challenge/${challenge_id}`);
        return;
      }

      //In case of user being owner: Attempt to load temporary workspace
      try {
        const workspace = await this.loadWorkspaceFromId(challenge_id);
        
        const remote_version = await Workspace.constructWorkspaceFromChallenge(challenge);
        
        await Workspace.mergeWorkspaceVersions(remote_version, workspace)
        await Workspace.save(workspace);
        console.log(workspace);
        this.workspace = await this.loadWorkspaceFromId(challenge_id);
        
      } catch {
        //No local copy - create a new workspace
        this.workspace = await Workspace.constructWorkspaceFromChallenge(challenge, { temporary : true, saveNow : true});
      }
      
    } else {
      //Not remote
      this.workspace = await this.loadWorkspaceFromId(challenge_id)
    }

  },
  setup() {
      const code = ref("");
      const isRemote = ref(false)
      const qualifiedUser = ref(true);
      return { code, isRemote, qualifiedUser }
  }
}
</script>

<style>

</style>