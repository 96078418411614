<template>
  <div v-if="signedIn" class="q-pa-md row items-start q-gutter-md">
    <div class="col-md-6">
      <q-btn flat @click="onCreateNewWorkspace">Create new workspace</q-btn>
      <div class="text-h6">Your workspaces</div>
      <q-list bordered separator>
        <q-item v-for="ws in workspaces" :key="ws._id">
          <q-item-section avatar>
            <q-img width="64px" :src="logosrc(ws.doc.environment)" />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              <div class="cursor-pointer">
                {{ ws.doc.name }}
                <q-popup-edit v-model="ws.doc.name" auto-save v-slot="scope" @save="onRenameSave"
                  @before-hide="onRenamed(ws)">
                  <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                </q-popup-edit>
              </div>
            </q-item-label>
            <q-item-label caption>{{ ws.doc.environment }} - {{ ws.doc.challenge }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <div class="text-grey-8 q-gutter-xs">
              <!--<q-btn class="gt-xs" size="16px" flat dense round icon="delete" @click="remove(ws.doc)" />-->
              <q-btn size="16px" color="secondary" flat dense round icon="edit" @click="edit(ws.doc)" />
              <q-btn v-if="submitted_workspaces[ws.doc.challenge] !== undefined" size="16px" color="primary" flat dense round icon="cloud_download" @click="editRemoteChallenge(ws.doc)" title="Edit submitted challenge" />
              <q-btn v-else size="16px" color="primary" flat dense round icon="play_arrow" @click="open(ws.doc)" title="Open this workspace"/>
              <!--<q-btn size="12px" flat dense round icon="more_vert" />-->
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="col-md-6">
      <q-btn @click="checkBackendRole">Test backend role</q-btn>
    </div>
  </div>
  <edit-workspace-dialog :showing="showEditWorkspace" :workspace="workspace" @saveWorkspace="saveWorkspace"
    @hide="onHide">
  </edit-workspace-dialog>
</template>

<script>

import { backend, evaluate } from '@/services'
import DB from '@/db'
import router from '@/router'
import Workspace from '@/workspace'
import Environment from '@/environments'
import EditWorkspaceDialog from '@/components/workspace/EditWorkspaceDialog'
export default {
  components: { EditWorkspaceDialog },
  data() {
    return {
      signedIn: false,
      workspace: null,
      workspaces: [],
      showEditWorkspace: false,
      rename: "",
      submitted_workspaces : {}
    }
  },
  methods: {
    onRenamed(ws) {
      this.rename = ws;
    },
    onRenameSave(value, initialValue) {
      Workspace.renameWorkspace(initialValue, value)
    },
    onCreateNewWorkspace() {
      this.workspace = null
      this.showEditWorkspace = true
    },
    async open(ws) {
      await Workspace.load(ws.name)

      switch (Workspace.current().mode) {
        case "sandbox":
          router.push(`/${ws.environment}/${ws.mode}`);
          break;
        case "challenge":
          router.push(`/challenge/${ws.challenge}`);
          break;
        case "challengeeditor":
          router.push(`/edit/${ws.challenge}`);
          break;
      }
    },
    async editRemoteChallenge(ws) {
      router.push(`/edit/remote/${ws.challenge}`)
    },
    edit(ws) {
      this.workspace = ws;
      this.showEditWorkspace = true
    },
    async onHide() {
      this.showEditWorkspace = false
      this.workspaces = await Workspace.getMyWorkspaces();

    },
    async remove(ws) {
      await Workspace.removeWorkspace(ws.name);
    },
    logosrc(environment) {
      try {
        let env = Environment.get(environment);
        return "/assets/tech/" + env.icon64;
      } catch {
        return "/assets/tech/questionmark64.png";
      }
    },
    async saveWorkspace(data, settings) {
      this.workspace.name = data.name;
      this.workspace.environment = data.environment;
      this.workspace.mode = data.mode;
      this.workspace.meta.author = data.meta.author;
      this.workspace.meta.modified = (new Date()).toISOString()
      this.workspace.settings = settings;

      this.updateWorkspace();

    },
    async createWorkspace(name, mode, environment) {
      const self = this;

      Workspace.createWorkspace(name, mode, environment)
        .then(async (ws) => {

          await Workspace.addDefaultWorkspaceFiles(ws)

          await Workspace.save(ws);

          self.root.success("Your new workspace is ready", self)
          this.$emit("hide");
        })
        .catch((error) => {
          var msg = error.msg || error
          self.$root.failed(msg, self)
        })
    },
    checkBackendRole() {
      
       backend.post("role", {
            username : DB.credentials.username,
            password : DB.credentials.password,
            role : "admin"
        }, {withCredentials : true})
    },
    updateWorkspace() {
      const self = this;
      if (this.workspace) {
        Workspace.save(this.workspace)
          .then(() => {
            self.$root.success("Workspace updated", self)
            self.onHide();
          })
          .catch((error) => {
            console.log(this.workspace._rev)
            self.$root.failed("Unable to update the workspace (" + error + ")", self)
            self.onHide();
          })

      }
    },
    /**
     * In order to show a download icon in the user workspaces it is needed to figure out if the challenge exists online.
     * If the workspace challenge exists online and the workspace is not a temporary workspace, the download icon is shown
     */
    async handleSubmittedChallenges() {
      var challenges = [];
      for (var i in this.workspaces) {
        if(this.workspaces[i].doc.challenge != "") {
          challenges.push(this.workspaces[i].doc.challenge);
        }
      }
      if (challenges.length > 0) {
        let submitted = await evaluate.get("challenges/", { params : { fields: ["uuid","state"], filterUuids : challenges} });
        this.submitted_workspaces = {}
        for (let i in submitted.data) {
          let s = submitted.data[i];

          this.submitted_workspaces[s["uuid"]] = s["state"]
        }
      }
    }
  },
  async mounted() {
    this.signedIn = await DB.checkUserStatus();
    this.workspaces = await Workspace.getMyWorkspaces();
    await this.handleSubmittedChallenges();
    if (!this.signedIn)
      router.replace("/user/signin");
  }
}
</script>

<style>
</style>