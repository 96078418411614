import axios from 'axios'
import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'

const database_host = "https://sofa.hoxer.net:6984";
const database_name = "techecute"
let remote_database = "";
PouchDB.plugin(PouchDBFind);
const PDB = new PouchDB(database_name);
const roles = [];
const credentials = {
    username : localStorage.getItem("username") || "",
    password : ""
}

PDB.createIndex({
    index: {
      fields: ['challenge']
    }
  }).then(function (result) {
    console.debug(result)
  }).catch(function (err) {
    console.log(err);
  });
  
  

let TDB = {}
if (process.env.NODE_ENV === 'development') {
    TDB = new PouchDB(database_name+"_tests");
}

var UNITTESTMODE = false;


var _isSignedIn = false
var _keepAlive = null;
var _onLoginStateChanged = () => {}
function isSignedIn() { return _isSignedIn }
function db() {
    return UNITTESTMODE ? TDB : PDB;
}
async function setUnitTestMode(val) {
    UNITTESTMODE = val;
    if (val == false) {
        await TDB.destroy();
        console.log("Removed test database")
        console.log("Switch database to "+database_name)
    } else {
        TDB = new PouchDB(database_name+"_tests");
        console.log("Switch database to "+database_name+"_tests")
    }

}
async function checkUserStatus(callback) {
    
    var response = await axios.get(database_host+"/_session", {withCredentials : true})
        .catch( () => {
            //console.error(error);
        })
        
    if (response && response.data && response.data.userCtx && response.data.userCtx.name && response.data.userCtx.name != null) {
        if (typeof(callback) == "function") callback(response.data)
        if (!_isSignedIn) 
            _onLoginStateChanged(true);
        _isSignedIn = true;
        localStorage.setItem("username", response.data.userCtx.name)
        setRoles(response.data.userCtx.roles);
        setKeepAlive();
    }
    if (response) {
        if (response.data.userCtx == null) {
        
            if (_isSignedIn) {
                _onLoginStateChanged(false);
                _isSignedIn = false;
            }
            localStorage.removeItem("username")
        }
        
    }
    return _isSignedIn;
}

function setRoles(newRoles) {
    while (roles.length) roles.pop();
    for( let i in newRoles) {
        roles.push(newRoles[i])
    }
}

function userHasRole(role) {
    return roles.indexOf(role) !== -1;
}

function setKeepAlive() {
    _keepAlive = setTimeout(
        async () => {
            
            await checkUserStatus()
        }
    ,60000); //nine minutes
}

function signIn(username, password) {
    credentials.username = username
    credentials.password = password
    return new Promise( (resolve, reject) => {
        axios.post(database_host+"/_session", {
            username : username,
            password : password
        }, {withCredentials : true})
            .then( result => {
                localStorage.setItem("username", username)

                if (result.data && result.data.roles) {
                    setRoles(result.data.roles);
                }
                remote_database = 'userdb-'+_hexEncode(username);
                sync();
                setKeepAlive();
                resolve(result);
            })
            .catch( e => {
                clearInterval(_keepAlive);
                reject(e)
            })
    });
}

function signOut() {
    return new Promise( (resolve, reject) => {
        axios.delete(database_host+"/_session", {withCredentials : true})
            .then( data => {
                console.log("Logged out")
                clearInterval(_keepAlive);
                resolve(data);
            })
            .catch( e => {
                clearInterval(_keepAlive);
                reject(e)
            })
    });
}

function sync() {
    db().sync(`${database_host}/${remote_database}`)
}
function put(doc) {
    return db().put(doc);
}
function get(id) {
    return db().get(id);
}
function remove(id) {
    return db().remove(id);
}
function _hexEncode(string) {
    var hex, result = "";
    for (let i = 0; i < string.length; i++) {
        hex = string.charCodeAt(i).toString(16);
        result += hex
    }
    return result;
}

function onLoginStateChanged(callback)
{
    _onLoginStateChanged = callback
}
function replicate() {
    return db().replicate
}
async function allDocs(request) { 
    var result = await db().allDocs(request)
        .catch ( err => {
            console.log(err);
        })
    return result; 
}
async function find(request, callback) {
    if (typeof callback !== "function") callback = () => {}
    var result = await db().find(request,callback).catch ( err => {
        console.log(err);
    })
    return result; 
}

async function remote_query(database, query) {
    return axios.post(`${database_host}/${database}/_find`, query
        , {withCredentials : true})
}

async function remote_getPendingChallenges() {
    let query = {
        "selector" : {
            "state": { "$eq": 1 }
        }
    }
    let result = null
    try {
        result = await remote_query("challenges", query)
        return result.data.docs
    } catch (e) {
        console.log(`Remote query failed: (${e})`)
    }
    return result;
}

/*PDB.replicate.to(PDB2).on("complete", () => {
    console.log("Replicated")
}).on("error", (err) => {
    console.log(err)

});*/
const DB = { signIn, isSignedIn, checkUserStatus, userHasRole, signOut, put, get, sync, 
    onLoginStateChanged, remove, replicate, allDocs, setUnitTestMode, find,
    credentials,
    remote_getPendingChallenges }
export default DB