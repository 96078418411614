<template>
      <q-dialog v-model="visible" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar v-if="icon" :icon="iconModel" color="primary" text-color="white" />
          <span class="q-ml-sm">{{message}}</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" @click="$emit('confirm', false)" v-close-popup />
          <q-btn flat :label="labelModel" color="primary" @click="$emit('confirm', true)" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
</template>

<script>
export default {
    props : {
        message: String,
        icon: String,
        label: String,
    },
    emits: ["confirm"],
    data() {
      return {
        visible : false,
        messageModel : "",
        iconModel : "",
        labelModel : ""
      }
    },
    watch : {
      message() {
        this.visible = this.message != ""
      }
    },
    mounted() {
      this.iconModel = this.icon || "info";
      this.labelModel = this.label || "Confirm";
    }
}
</script>

<style>

</style>