import assert from '@/assert'
import Workspace from '@/workspace'
const tests = {
    async createWorkspaceMissingName() {
        await Workspace.createWorkspace("", "sandbox", "dotnet6").catch(
            (err) => {
                assert.Equal(1, err.err)
            }
        )
    },
    async createWorkspace_ValidateMode() {
        //Missing Workspace mode
        await Workspace.createWorkspace("my Workspace", "", "dotnet6").catch(
            (err) => {
                assert.Equal(2, err.err)
            }
        )
        //Invalid mode
        await Workspace.createWorkspace("my Workspace", "flanders", "dotnet6").catch(
            (err) => {
                assert.Equal(3, err.err)
            }
        )
        //Correct mode (invalid environment)
        await Workspace.createWorkspace("my Workspace", "sandbox", "").catch(
            (err) => {
                assert.Equal(4, err.err)
            }
        )
    },
    async createWorkspace_ValidateEnvironment() {
        let name = "createWorkspace_ValidateEnvironment_test"+(new Date).getTime();
        //Missing Workspace mode
        await Workspace.createWorkspace(name, "sandbox", "", ).catch(
            (err) => {
                assert.Equal(4, err.err)
            }
        )

        //Invalid mode
        await Workspace.createWorkspace(name, "sandbox", "INVALID_ENVIRONMENT").catch(
            (err) => {
                assert.Equal(5, err.err)
            }
        )
        //Correct environment
        let doc = await Workspace.createWorkspace(name, "sandbox", "dotnet6").catch(
            (err) => {
                console.log(err);
                assert.Equal(6, err.err)
            }
        )
        assert.HasValue(doc._id);
    },
    async removeWorkspace() {
        let name = "test-removeWorkspace"+(new Date).getTime()
        let doc = await Workspace.createWorkspace(name, "sandbox", "dotnet6", {saveNow : true}).catch(
            (err) => {
                console.log(err);
                assert.Null(err.msg)
            }
        )
        assert.HasValue(doc._id, "Expecting a loaded Workspace");

        let list = await Workspace.getMyWorkspaces();
        let found = false;
        for (var i in list) {
            if ("workspace:"+name == list[i].id) {
                found = true
                break;
            }
        }
        assert.True(found, "Expecting to find the Workspace in the getMyWorkspaces list");

        let result = await Workspace.removeWorkspace(name).catch( (err) => { assert.Null(err.msg)});
        assert.True(result.ok, "Expecting the result of removing the Workspace to be ok");

        list = await Workspace.getMyWorkspaces();
        for (i in list) {
            assert.NotEqual(name, list[i].name, "The name should not be in getMyWorkspaces list");
        }
        
    },
    async getMyWorkspaces() {
        let name1 = "test-1-getMyWorkspaces"+(new Date).getTime()
        let name2 = "test-2-getMyWorkspaces"+(new Date).getTime()
        let name3 = "test-3-getMyWorkspaces"+(new Date).getTime()
        
        await Workspace.createWorkspace(name1, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.createWorkspace(name2, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.createWorkspace(name3, "sandbox", "dotnet6", { saveNow : true });
        let list = await Workspace.getMyWorkspaces();
        
        let count = 0;
        for (var i in list) {
            var c = parseInt(i) + 1;
            if (list[i].id.indexOf("workspace:test-"+c+"-getMyWorkspaces") === 0) count++;
        }
        assert.Equal(3, count, "Should be three Workspaces in my Workspaces after adding three");

    },
    async renameWorkspace() {
        let oldname = "test-renameWorkspace-old-"+(new Date).getTime()
        let newname = "test-renameWorkspace-new-"+(new Date).getTime()
        await Workspace.createWorkspace(oldname, "sandbox", "dotnet6", { saveNow : true });
        let doc = await Workspace.load(oldname).catch( () => {});
        assert.HasValue(doc)

        await Workspace.renameWorkspace(oldname, newname);
        doc = await Workspace.load(newname);
        assert.HasValue(doc)

        let nulldoc = await Workspace.load(oldname).catch( ()=>{} );
        assert.HasNoValue(nulldoc)

        //Cleanup
        await Workspace.removeWorkspace(newname);
    },
    async loadWorkspace_NoSuchWorkspace() {
        let name = "test-loadWorkspace_NoSuchWorkspace-"+(new Date).getTime()
        let notFound = false;
        await Workspace.load(name).catch( () => {
            notFound = true;
        });
        assert.True(notFound)
        
    },
    async loadWorkspace_ExistingWorkspaceIsLoaded() {
        let name = "test-loadWorkspace_ExistingWorkspaceIsLoaded-"+(new Date).getTime()
        await Workspace.createWorkspace(name, "sandbox", "dotnet6", { saveNow : true });
        let result = await Workspace.load(name).catch( e=> {
            assert.Null(e);
        });
        
        assert.Equal("object", typeof(result));
        assert.Equal(name, result.name);

        assert.Equal(name, Workspace.current().name)
        
        //Cleanup
        await Workspace.removeWorkspace(name);
    },
    async getCurrentWorkspace() {
        let name1 = "test-1-getCurrentWorkspace-"+(new Date).getTime()
        let name2 = "test-2-getCurrentWorkspace-"+(new Date).getTime()
        assert.Null(Workspace.current())

        await Workspace.createWorkspace(name1, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.load(name1).catch( e=> {
            assert.Null(e);
        });
        assert.Equal(name1, Workspace.current().name);

        await Workspace.createWorkspace(name2, "sandbox", "dotnet6", { saveNow : true });

        await Workspace.load(name2).catch( e=> {
            assert.Null(e);
        });
        assert.Equal(name2, Workspace.current().name);

        await Workspace.load(name1).catch( e=> {
           
            assert.Null(e);
        });
        assert.Equal(name1, Workspace.current().name);

        //Cleanup
        await Workspace.removeWorkspace(name1);
        await Workspace.removeWorkspace(name2);

        assert.Null(Workspace.current())
        
    },
    async saveWorkspace() {
        let name = "test-saveWorkspace-"+(new Date).getTime();
        await Workspace.createWorkspace(name, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.load(name)

        Workspace.current().environment = "python";
        await Workspace.save();

        //Create another Workspace and load it
        let name2 = "test-saveWorkspace2-"+(new Date).getTime();
        await Workspace.createWorkspace(name2, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.load(name2)

        //Load back the first Workspace to see the environment has changed
        await Workspace.load(name)

        assert.Equal("python", Workspace.current().environment)

        //Cleanup
        await Workspace.removeWorkspace(name);
        await Workspace.removeWorkspace(name2);

    },
    async latestWorkspaceFromLocalStorage() {
        let name = "test-saveWorkspace3-"+(new Date).getTime();
        await Workspace.createWorkspace(name, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.load(name)

        assert.Equal(name, localStorage.getItem(`last:dotnet6:sandbox`))

        //Cleanup
        await Workspace.removeWorkspace(name);

    },
    async createFileInWorkspace_fileExists() {
        let name = "test-createFileInWorkspace_fileExists-"+(new Date).getTime();
        let ws = await Workspace.createWorkspace(name, "sandbox", "dotnet6");
        assert.False(Workspace.fileExists("test.cs", ws));
        Workspace.createFile("test.cs", "dotnet6", ws);
        
        assert.True(Workspace.fileExists("test.cs", ws));
        assert.False(Workspace.createFile("test.cs", "dotnet6", ws));
        
    },
    async createFileInWorkspace_validFilename() {
        let name = "test-createFileInWorkspace-"+(new Date).getTime();
        let ws = await Workspace.createWorkspace(name, "sandbox", "dotnet6");
        Workspace.createFile("test.cs", "dotnet6", ws);
        let shouldBeFalse = Workspace.createFile("test.cs", "dotnet6", ws);
        assert.False(shouldBeFalse);

    },
    async createFileInWorkspace_invalidFilename() {
        let name = "test-createFileInWorkspace-"+(new Date).getTime();
        let ws = await Workspace.createWorkspace(name, "sandbox", "dotnet6");
        try {
            var result = Workspace.createFile("test`.cs", "dotnet6", ws);
        } catch (e) {
            assert.Equal("Invalid filename", e);
        }
        assert.NotEqual(true, result);
    },
    async removeFileInWorkspace_fileexists() {
        let name = "test-createFileInWorkspace-"+(new Date).getTime();
        let ws = await Workspace.createWorkspace(name, "sandbox", "dotnet6");
        Workspace.createFile("file1.cs", "dotnet6", ws);
        Workspace.createFile("file2.cs", "dotnet6", ws);
        
        var count = 0;
        var nop = ()=>{}
        for (var _ in ws.files) {
            nop(_)
            count ++;
        }
        assert.Equal(2, count)

        Workspace.removeFile("file1.cs", ws);
        count = 0;
        for (_ in ws.files) {
            nop(_)
            count ++;
        }

        assert.Equal(1, count)
    },
    async removeFileInWorkspace_removeLastFileReference() {
        let name = "test-removeFileInWorkspace_removeLastFileReference-"+(new Date).getTime();
        Workspace.createWorkspace(name, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.load(name);
        
        Workspace.createFile("file1.cs", "dotnet6");
        assert.Equal("file1.cs",Workspace.current().currentFile);
        Workspace.createFile("file2.cs", "dotnet6");
        await Workspace.removeFile("file1.cs");
        assert.Equal("file2.cs",Workspace.current().currentFile);

        
    },
    async renameFileInWorkspace() {
        let name = "test-createFileInWorkspace-"+(new Date).getTime();
        let ws = await Workspace.createWorkspace(name, "sandbox", "dotnet6");
        Workspace.createFile("program.cs", "dotnet6", ws);
        assert.HasNoValue(ws.files["Program.cs"]);

        Workspace.renameFile("program.cs", "Program.cs", ws);
        assert.HasValue(ws.files["Program.cs"]);
        
        assert.HasNoValue(ws.files["program.cs"]);
    },
    async getCurrentFile() {
        let name = "test-getCurrentFile-"+(new Date).getTime();
        await Workspace.createWorkspace(name, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.load(name);

        let noFile = false
        try {
            Workspace.currentFile()
        } catch {
            noFile = true
        }
        assert.True(noFile);
            
        Workspace.createFile("main.py", "python3");

        assert.Equal("main.py", Workspace.current().currentFile);   //Current file should be main.py
        
        assert.Equal("python3", Workspace.currentFile().type);      //Current file type should be "python3"
        Workspace.createFile("readme.txt", "text");
        assert.Equal("readme.txt", Workspace.current().currentFile);    //Current file should now be readme.txt
        assert.Equal("text", Workspace.currentFile().type);      //Current file type should be "python3"

        //Cleanup
        await Workspace.removeWorkspace(name);
    },

    async getFileContent() {
        let name = "test-getCurrentFile-"+(new Date).getTime();
        await Workspace.createWorkspace(name, "sandbox", "dotnet6", { saveNow : true });
        await Workspace.load(name);
        var content = "Hello world";

        Workspace.createFile("Program.cs", "dotnet6")
        var content2 = Workspace.fileContent(content);
        assert.Equal(content, Workspace.current().files["Program.cs"].content)
        assert.Equal(content, content2);
        assert.Equal(content, Workspace.fileContent())
    },

    async constructWorkspaceFromChallenge() {
        const valid_challenge = {
            uuid : '221fc387-11b9-451a-8406-9b429ec665b2',
            name : "The simple sum method",
            introduction : "Create a method that returns the sum of two numbers! The method accepts two int arguments, adds them together and returns the result",
            instructions : "Create a method that returns the sum of two numbers! The method accepts two int arguments, adds them together and returns the result",
            environment : "dotnet6",
            difficulty : 1,
            start_code : {
                "Program.cs" : `class Program
        {
        public static int Sum(int a, int b)
        {
        
        }
        }`
            },
            cursor_position : [
                "Program.cs", 4, 8
            ],

            meta : {
                author : "Konrad",
                created : (new Date()).toISOString(),
                modified : 0
            },

            unit_tests : `Assert.Equal(20, Program.Sum(10,10));
        `
            
        };

        var workspace = await Workspace.constructWorkspaceFromChallenge(valid_challenge).catch((e)=>{
            console.error(e)
        })
        
        assert.Equal(valid_challenge.name, workspace.name, "The names were not equal");
        assert.Equal(valid_challenge.environment, workspace.environment, "Environment was not set correctly");
        assert.Equal("challengeeditor", workspace.mode, "Mode should be 'challengeeditor'");
        assert.HasValue(workspace.challenge, "Workspace challenge should be set");
        assert.Equal(valid_challenge.start_code['Program.cs'], Workspace.fileContent(null, "Program.cs", workspace), 'The contents of workspace file Program.cs should match the challenge')
        assert.Equal(valid_challenge.introduction, Workspace.fileContent(null, "Introduction.md", workspace), 'The contents of workspace file Introduction.md should match the challenge');
        assert.Equal(valid_challenge.instructions, Workspace.fileContent(null, "Instructions.md", workspace), 'The contents of workspace file Instructions.md should match the challenge');
        assert.Equal(valid_challenge.unit_tests, Workspace.fileContent(null, "Tests.cs", workspace), 'The contents of workspace file Tests.cs should match the challenge');
        assert.True(Workspace.validateWorkspace(workspace));
    },
    async setAndGetSettings() {
        let name = "test-setAndGetSettings-"+(new Date).getTime();
        let ws = await Workspace.createWorkspace(name, "sandbox", "dotnet6");
        assert.Null(Workspace.getSetting("unsetKey",ws), "A key was not set and therefore getSetting() should return null");
        const value = "My setting!";
        Workspace.setSetting("key_set", value, ws);
        assert.Equal(value, Workspace.getSetting("key_set", ws), "The value loaded from settings should be equal to the stored value");

        Workspace.setSetting("key_set", undefined);
        assert.Null(Workspace.getSetting("key_set"), "The setting has been deleted and therefore getSetting() should return null")
    },
    async mergeWorkspaceVersions() {
        const force = true
        const source = await Workspace.createDefaultWorkspace("dotnet6")
        
        source.meta.author = "konrad";
        source.meta.created = (new Date()).toISOString();
        source.meta.modified = (new Date()).toISOString();

        let destination = await Workspace.createWorkspace("Destination", "sandbox", "python3");
        
        try {
            Workspace.mergeWorkspaceVersions(source, destination)
            
            assert.True(false, "Workspaces contains different IDs. Merging should have thrown an error");
        } catch (e) {
            assert.Equal("Cannot merge workspaces. Document id mismatch",e)
        }
        
        destination = await Workspace.createWorkspace("Destination", "challenge", "dotnet6", { challenge : "221fc387-11b9-451a-8406-9b429ec665b2"});
        destination._id = source._id
        try {
            Workspace.mergeWorkspaceVersions(source, destination)
            assert.True(false, "Workspaces contains different modes. Merging should have thrown an error");
        } catch (e) {
            assert.Equal("Cannot merge workspaces. Modes does not match",e)
        }

        destination = await Workspace.createWorkspace("Destination", "sandbox", "python3");
        destination._id = source._id
        try {
            Workspace.mergeWorkspaceVersions(source, destination)
            assert.True(false, "Workspaces contains different environments. Merging should have thrown an error");
        } catch (e) {
            assert.Equal("Cannot merge workspaces. Environments does not match",e)
        }


        destination = await Workspace.createWorkspace("Destination", "sandbox", "python3");
        try {
            Workspace.mergeWorkspaceVersions(source, destination, force)
            assert.Equal(source.environment, destination.environment)
            assert.Equal(source.mode, destination.mode)
            assert.Equal(source.meta, destination.meta)
            assert.Equal(source.files, destination.files)
        } catch (e) {
            assert.True(false, "Document should have merged");
        }

        
    }
}

export default tests;
