
import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Dialog
  }
}