import { createRouter, createWebHistory } from 'vue-router'
//import { CodeEditor } from '../views';
import { CodeHome, CodeInstructions, CodeSandbox, ChallengeList, LandingPage, CodeChallenge, EditChallenge } from '../views'
import { CodeEnvironment } from '../views/code'
import { ModerationHome } from '../views/moderation'
import { UserEdit, UserLogin, UserProfile } from '../users'
import NotFound from '@/views/NotFound.vue'
import UnitTest from '@/views/UnitTest.vue'
import HelperView from '@/views/HelperView.vue'
//import Environment from '@/environments'


const routes = [
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
  {
    path: '/',
    name: 'start',
    component: LandingPage
  },
  {
    path: '/code',
    name: 'code',
    component: CodeHome
  },
  /*{
    path: '/editor',
    name: 'code editor',
    component: CodeEditor
  },*/
  {
    path: '/instructions',
    name: 'coding instructions',
    component: CodeInstructions
  },
  {
    path: '/sandbox',
    name: 'Experiment with sandbox',
    component: CodeEnvironment
  },
  {
    path: '/challenges',
    name: 'Learn through challenges',
    component: CodeEnvironment
  },
  {
    path: '/moderation',
    name: 'Maintain challenges',
    component: ModerationHome
  },
  {
    path: "/:environment/challenge",
    component : ChallengeList
  },
  {
    path: '/challenge/:challenge',
    component: CodeChallenge
  },
  {
    path: '/user/register',
    component: UserEdit
  },
  {
    path: '/user/signin',
    component: UserLogin
  },
  {
    path: '/user/profile',
    component: UserProfile
  },
  {
    path: '/environment',
    component : CodeEnvironment
  },
  {
    path: "/:environment/sandbox",
    component : CodeSandbox
  },
  {
    path: '/edit/remote/:challenge',
    component : EditChallenge
  },
  {
    path: '/edit/:challenge',
    component : EditChallenge
  }
]

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: "/test",
    component: UnitTest
  })
  routes.push({
    path: "/helper",
    component: HelperView
  })
}
/*let env = Environment.getKeyValues()
for(let key in env) {
  if (env[key].modes.indexOf("sandbox") !== -1) {
    routes.push({
      path : `/${key}/sandbox`,
      component : CodeSandbox
    })
  }
}*/

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
