<template>
  <code-tabs></code-tabs>
  <div class="q-pa-md row items-start q-gutter-md">

      <div class="col">
          
        <code-editor
          @runState="onRunStateChanged" 
            :workspace="workspace" 
            :mode="'challenge'"
            :environment="environment"></code-editor>
      </div>
      <div class="col">
        <code-output :output="output" :state="state"></code-output>
    </div>
  </div>
  
</template>

<script>
import CodeEditor from "@/components/CodeEditor.vue"
import CodeTabs from "@/components/CodeTabs.vue"
import CodeOutput from "@/components/CodeOutput.vue"
//import router from './router'
import {evaluate} from "@/services"
import { headerPaneStore } from '@/stores/header'
import { ref } from 'vue'
import Workspace from '@/workspace'


export default {
  components : {
    CodeTabs,
    CodeEditor,CodeOutput
  },
  data() {
    return {
      output : "",
      state : -1,
      workspace : null,
      environment : ""
    }
  },
  methods : 
  {
    onRunStateChanged(state, result) {
      this.state = state;
      switch (state) 
      {
        case 0: 
          this.output = "Error: "+result;
          break;
        case 1:
          //this.output = "Running";
          break;
        case 2: 
          this.output = result;
          break;
      }
    }
  },
  mounted() {
    let self = this;
    this.uuid = this.$route.params.challenge
    const headerPane = headerPaneStore();
    headerPane.show();

    var url = "/challenge/"
      + this.$route.params.challenge
    
    evaluate.get(url)
      .then(async (response) => {
        var json = response.data;
        if (typeof(json) != "object" || Array.isArray(json) !== false) {
          throw "Received invalid challenge from server"
        }
        json.uuid = self.uuid;
        var workspaceName = `Challenge: ${json.name}`;
        await Workspace.load(workspaceName)
          .catch( async(e) => {
          if (e.status == 404) {
            let options = {
              challenge : json.uuid
            }
            await Workspace.createWorkspace(workspaceName,"challenge", json.environment, options);
            var workspace = await Workspace.load(workspaceName);
            if (!workspace) throw `Unable to load newly created workspace: ${workspaceName}`

            for (var file in json.start_code) {
              Workspace.createFile(file, json.environment, workspace)
              Workspace.fileContent(json.start_code[file], file, workspace)
              break;
            }
            
            Workspace.save();
          }
        })
        self.workspace = Workspace.current();
        self.environment = self.workspace.environment;

        self.challenge = json;
        
      })
      .catch( e => {
        console.error(e);
      }); 
  },
  setup() {
      const code = ref("");
      return { code }
  }
}
</script>

<style>

</style>