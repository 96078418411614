<template>
<q-toolbar>

</q-toolbar>
<q-card flat bordered class="my-card">
    <q-card-section v-if="state==-1">
        <pre>No output yet, try code</pre>
    </q-card-section>
    <q-card-section v-else-if="state==0">
         <div class="text-h6">Error</div>
        <pre>{{output}}</pre>
    </q-card-section>
    <q-card-section v-else-if="state==1">
        <div class="text-h6">Output</div>
        <br>
        <q-spinner-gears
          color="bg-blue-grey-7"
          size="2em"
          
        />
        Running...<br>
    </q-card-section>
    <q-card-section v-else-if="state==2">
         <div class="text-h6">Output</div>
        <pre>{{output}}</pre>
    </q-card-section>
</q-card>
</template>

<script>
export default {
    props : {
        output : String,
        state : Number
    }
}
</script>

<style>

</style>