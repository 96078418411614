<template>
  <div class="q-pa-md row items-start q-gutter-md justify-start">
      <div class="col-3">
        Filter
      </div>
      <div class="col-8 items-start q-gutter-md">
        <q-card v-if="skeletonVisible">
          
          <q-card-section class="col-md-6">
              <div class="text-h6" style="margin-bottom:6px"><q-skeleton :type="rect" width="300px"/></div>
               
              <div><q-skeleton height="50px" width="60%"  :type="QInput" /></div>
            </q-card-section>
        </q-card>
        <q-card 
          v-for="challenge in challenges"
          :key="challenge.uuid" 
          q-gutter-md >
          <q-card-section @click="gotoChallenge(challenge.uuid)" horizontal class="challenge-card row justify-between">
            <q-card-section class="col-md-6">
              <div class="text-h6">{{challenge.name}}</div>
              <div>{{challenge.introduction}}</div>
            </q-card-section>
            <q-card-actions vertical class="col-md-1 on-right" >
              <q-btn flat round color="blue-grey-7" icon="more_horiz" size="lg" class="vertical-bottom" />
              <!--<q-btn flat round color="accent" icon="bookmark" size="lg"  />
              <q-btn flat round color="primary" icon="share" />-->
            </q-card-actions>
            
          </q-card-section>
        </q-card>

        <q-btn @click="createChallenge">Create challenge</q-btn>
    </div>
  </div>
</template>

<script>
import {evaluate} from '@/services'
import router from '@/router'
import { headerPaneStore } from '@/stores/header'
import { ref } from 'vue';
import Workspace from '@/workspace';
import Environment from '@/environments';
import {useQuasar} from 'quasar'
export default {
  methods : {
    gotoChallenge(uuid) {
      router.push("/challenge/"+uuid)
    },
    async createChallenge() {
      if (Environment.get(this.environment) == null) {
        console.error("Unable to determine environment");
      }
      
      const name = await this.promptChallengeName();
      
      if (name) {
        
        const workspace = await Workspace.createWorkspace(name, "challengeeditor",this.environment.toString());
        await Workspace.addDefaultWorkspaceFiles(workspace);
        await Workspace.load(name)
        router.push(`/edit/${workspace.challenge}/`);
        this.$root.success("Nice! You can now prepare the challenge! Work on it now or later. Upload it when you're done.")
      }
      

    }
  },
  mounted() {
    let self = this;
    
    const headerPane = headerPaneStore()
    headerPane.show();
    evaluate.get("/challenges").then( (response) => {
      self.challenges = response.data
      self.skeletonVisible = false
    })
    .catch( (err) => console.error(err) );
  },
  setup() {
    const challenges = ref([]);
    const environment = ref("");
    const skeletonVisible = ref(true)
    const $q = useQuasar();

    function promptChallengeName () {
      return new Promise((res, reject) => {
          $q.dialog({
          title: 'Name your challenge',
          message: 'What should be the title of the challenge? You can change this later',
          prompt: {
            model: '',
            type: 'text' // optional
          },
          cancel: true,
          persistent: true
        }).onOk(data => {
          res(data)
        }).onCancel(() => {
          reject(true)
        }).onDismiss(() => {
          reject(true)
        })
      })
    }



    return {challenges, environment, promptChallengeName, skeletonVisible}
  },
  created() {
    var self = this;
    this.$watch(
      () => this.$route.params,
      (toParams) => {
        if (Environment.exists(toParams))
          self.environment = toParams;
      }
    )
    this.environment = this.$route.params.environment
  }
}
</script>

<style>
.challenge-card:hover {
  background-color:rgb(37, 37, 37);
  cursor: pointer;
}
</style>