<template>
<q-layout>
    <q-page-container>
        <q-page class="window-height window-width row justify-center items-center">
            <div class="text-h1 text-right">Play<br>&nbsp;<br>&nbsp;Learn<br>&nbsp;
            <q-btn class="bg-blue-grey-7" @click="go('/sandbox')" glossy label="Get started" /></div>
            <div class="text-h1 text-left">Code<br>&nbsp;</div>
        </q-page>
    </q-page-container>
</q-layout>
</template>

<script>
import router from '../router'
import { headerPaneStore } from '@/stores/header'
export default 
{
    methods : {
        go(url)  {
            router.push(url);
        },
    },
    mounted() {
        const headerPane = headerPaneStore();
        headerPane.visible = false
    }
}
</script>

<style>

</style>