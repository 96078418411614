
let results = {}
let currentTest = null;
function result(passed, messageIfFailed, comment) {
    if (comment == undefined) comment = "";
    if (!passed) {
        var err = new Error();
        var stack_line = err.stack.split("\n")[2];
        var assert_line = stack_line.split("///")[1]
        messageIfFailed += " in "+assert_line;
    }
    if (results[currentTest] == undefined) {
        results[currentTest] = { 
            ok : passed, 
            msg : passed ? "Passed" : messageIfFailed,
            comment : comment
        }
    }
    if (results[currentTest].ok && !passed) {
        
        //messageIfFailed += " in "+console
        results[currentTest].ok = false;
        results[currentTest].msg = messageIfFailed;
        results[currentTest].comment = comment;
    }
}
function Equal(expected, actual, comment) {
    result(expected === actual, `Not equal. Expected ${expected}, got ${actual}`,comment);
}
function NotEqual(expected, actual, comment) {
    result(expected !== actual, `Equal, should not be. Compared ${expected} to ${actual}`, comment);
}
function LaxEqual(expected, actual, comment) {
    result(expected == actual, `Not equal. Expected ${expected}, got ${actual}`, comment);
}
function Null(actual, comment) {
    result(actual === null, `Not null. Expected null, got ${actual}`, comment);
}
function NotNull(actual, comment) {
    result(actual === null, `Is null. Expected not null, got null`, comment);
}
function True(actual, comment) {
    result(actual === true, `Not true. Expected true`, comment);
}
function False(actual, comment) {
    result(actual === false, `Not false. Expected false`, comment);
}
function HasValue(actual, comment) {
    result(actual === false || actual === true || actual != "" || actual != null || actual != undefined, `Has no value. Expected a value, got ${actual} (${typeof(actual)})`, comment);
}
function HasNoValue(actual, comment) {
    result(actual === "" || actual === null || actual === undefined, `Has value. Expected no value, got ${actual} (${typeof(actual)})`, comment);
}

async function Run(tests) {
    
    for (let k in tests) {
        currentTest = k;
        try {
            if (typeof(tests[k]) != "function") {
                console.error(`${k} is not a unit test: `+typeof(tests[k]));
                console.log(tests[k])
                continue;
            }
            await tests[k]();
        } catch (e) {
            console.error(`Test ${k} threw exception before it was finished:`)
            console.error(e);
        }

    }

    
    
}
function Complete() {
    //var ok = true;
    var passed = 0;
    var total = 0;
    for (let test in results){
        total++;
        if (results[test].ok) {
            passed++;

        } else {
            console.info(`${test} failed: ${results[test].msg}. ${results[test].comment}`);    
        }
    }
    if (total == passed && total > 0)
        console.info("All tests passed")
    else
        console.info(`Ran ${total} tests. ${passed} passed.`)
    
}

export default { Run, Complete, Equal, LaxEqual, NotEqual, Null, NotNull, True, False, HasValue, HasNoValue }
//export default { assert }