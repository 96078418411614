import { encode, decode, sha256, md5 } from './util/digest'
import { createUUID } from './util/uuid'

const MessageBox = {
    success(message,component) {
        if (!component || !component.q) throw "Missing q property quasar enabled component ";
        component.q.notify({
            color: 'green-4',
            textColor: 'white',
            spinner: false,
            icon: 'done',
            message: message
        })
    },
    failed(message, component) {
        if (!component || !component.q) throw "Missing q property quasar enabled component ";
        component.q.notify({
            color: 'red-5',
            textColor: 'white',
            spinner: false,
            icon: 'error',
            message: message});
        
    },
    warning(message, component) {
        if (!component || !component.q) throw "Missing q property quasar enabled component ";

        component.q.notify({
            color: 'orange-5',
            textColor: 'white',
            spinner: false,
            icon: 'warning',
            message: message});
        
    }
}


export {encode, decode, sha256, md5, createUUID, MessageBox}