const environmentKeys = ["dotnet6", "python3", "rust"];
const environmentExt = {"cs" : "dotnet", "py" : "python3", "rs" : "rust", "md" : "markdown"}
const environments = [
    {
        key : "dotnet6",
        title : 'C# (dotnet6)',
        icon64 : 'c-sharp64.png',
        modes : ['sandbox','challenge'],
        testfile : "Tests.cs",
        startingfiles : { 
            "sandbox" : [{
                filename : 'Program.cs',
                type : "dotnet6",
                content : `class Program
{
    public static void Main(string[] args)
    {
        Console.WriteLine("Hello world");
    }
}`
            }],
            "challengeeditor" : [
            {
                filename : 'Program.cs',
                type : "dotnet6",
                content : `class Program
{
    public static int Sum(int a, int b) 
    {
        return 0;
    }
}`
            },
            {
                filename : 'Introduction.md',
                content : "A short introduction"
            },
            {
                filename : 'Instructions.md',
                content : "Write the instructions here"
            },
            {
                filename : 'Tests.cs',
                content : `//Assert.Equal(4, Program.Sum(2,2), "2 and 2 is 4)
//Assert.True(1 == Program.Sum(-2,3), "Negative numbers and positive numbers should add up")
//Assert.Null(13 == Program.Sum(-10,-3), "Two negatives are even lower")
`
            }]
        }
    },
    {
        key : 'python3',
        title : "Python 3",
        icon64 : 'python3_64.png',
        modes : ['sandbox'],
        startingfiles : { 
                "sandbox" : [{
                    filename : 'main.py',
                    content : `print("Hello world")`
            }]
        }
    },
    {
        key : 'rust',
        title : "Rust",
        icon64 : 'rust64.png',
        modes : ['sandbox'],
        startingfiles : { 
            "sandbox" : [{
            filename : 'main.rs',
            content : `fn main() {
    println!("Hello, world!");
}`
            }]
        }
    }
]
function validateMode(mode)
{
    let valid_modes = ["sandbox", "challenge"];
    return valid_modes.indexOf(mode) === -1
}

/**
 * 
 * @param {string} [mode] optional. Filters environments by mode
 * @returns 
 */
function getKeyValues(mode) {
    if (typeof(mode) == "string" && mode != "") validateMode(mode);
    else mode = false;
    

    var env = {}
    for (let i = 0; i < environments.length; i++) {
        if (!mode || mode && environments[i].modes.indexOf(mode) !== -1)
            env[ environments[i].key ] = {
                title : environments[i].title,
                icon64 : environments[i].icon64,
                modes : environments[i].modes
            }
    }
    return env;
}
function getKeys() {
    let keys = [];
    for (let i = 0; i < environments.length; i++) {
        keys.push(environments[i].key);
    }
    return keys;
}
function getList(mode) {
    if (typeof(mode) == "string" && mode != "") validateMode(mode);
    else mode = false;
    
    var env = []
    for (let i = 0; i < environments.length; i++) {
        if (!mode || mode && environments[i].modes.indexOf(mode) !== -1)
            env.push({
                key : environments[i].key,
                title : environments[i].title,
                icon64 : environments[i].icon64,
                modes : environments[i].modes
            });
    }
    return env;
}

function getStartingFiles(environment, mode) {
    for( let i in environments ) {
        if (environments[i].key == environment) {
            return environments[i].startingfiles[mode];
        }
    }
    throw `Unknown environment '${environment}'`
}   

function exists(key) {
    for (let i = 0; i < environments.length; i++) {
        if (environments[i].key == key) 
            return true;
    }
    return false;
}

function get(key) 
{
    for (let i = 0; i < environments.length; i++) {
        if (environments[i].key == key) 
            return environments[i];
    }
    return null;
}

function getEnvironments() {
    return environmentKeys;
}

function fileExt2Env(ext) {
    if (ext.indexOf(".") !== -1) {
        ext = ext.split(".").pop();
    }
    if (environmentExt[ext])
        return environmentExt[ext]
    return null
}

export default { getKeyValues, getList, exists, get, getKeys, getStartingFiles, getEnvironments, fileExt2Env}