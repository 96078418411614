<template>
    <q-card>
        <q-form
            @submit="onSubmit"
            class="q-gutter-md">
            <q-card-section>
                <div class="text-h6" v-if="editing">Editing '{{workspace.name}}'</div>
                <div class="text-h6" v-else>Creating new workspace</div>
            </q-card-section>
            <q-card-section class="q-pt-none">
                <q-input filled
                        label="Workspace name"
                        v-model="nameModel"
                        :rules="[ val => val && val.length >= 3 || 'Workspace name should be at least 4 characters']"></q-input>
                
                <q-select filled 
                    label="Environment"
                    v-model="envModel"
                    :options="validEnvironments"
                    :disable="editing">
                    
                    <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section avatar>
                                <img :src="scope.opt.icon" style="width:32px" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ scope.opt.label }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select><br>
                
                <q-select filled
                    label="Type"
                    v-model="modeModel"
                    :options="validModes"
                    :disable="editing">
                    </q-select><br>
                    <div>Other data</div>
                    <q-input v-model="metaModel.author" label="Author" />
                
            </q-card-section>
            <edit-workspace-details-challenge-editor
                v-if="workspace != null && workspace.mode == 'challengeeditor'"
                :workspace="workspace"
                @changed="changed"></edit-workspace-details-challenge-editor>
            <q-card-actions>
                <q-btn v-if="editing" flat label="Delete" color="red-8" @click="confirmDelete" />
                <q-space></q-space>
                <q-btn flat label="Cancel" color="primary" v-close-popup />
                <q-btn flat label="Save workspace" type="submit" color="primary" />
            </q-card-actions>
        </q-form>
        <confirm-box @confirm="deleteConfirmation" :message="deleteMessage" :label="'Delete workspace'"></confirm-box>
    </q-card>
</template>

<script>
import Environments from "@/environments";
import workspace from "@/workspace";
import ConfirmBox from '@/components/ConfirmBox.vue'
import EditWorkspaceDetailsChallengeEditor from "./EditWorkspaceDetailsChallengeEditor.vue";
import { useQuasar } from 'quasar'
export default {
    components : {ConfirmBox,EditWorkspaceDetailsChallengeEditor},
    props : {
        //editing : Boolean,
        workspace : Object,
        
    },
    data() {
        
        return {
            nameModel : "",
            envModel : "",
            modeModel : "",
            diffModel : "",
            metaModel : {
                created : 0,
                modified : 0,
                author : "",
            },
            validEnvironments : null,
            validModes : [
                {label : "Sandbox",value : "sandbox"},
                {label : "Create/edit challenge", value : "challengeeditor"},
                {label : "Solve a challenge", value : "challenge", disable: true},
                ],
            deleteMessage : "",
            settings : {}
            
        }
    
    },
    computed : {
        editing() {
            return this.workspace != null
        }
    },
    methods : {
        onSubmit() {
            const data = {
                name : this.nameModel,
                mode : this.modeModel,
                environment : this.envModel,
                meta : this.metaModel
            }
            this.$emit("saveWorkspace", data, this.settings);
        },
        confirmDelete() {
            if (this.workspace && this.workspace.name)
                this.deleteMessage = "Please confirm deleting "+this.workspace.name;
        },
        deleteConfirmation(confirmed) {
            var self = this;
            if (confirmed) {
                workspace.removeWorkspace(this.workspace.name)
                .then( () => {self.$root.info("Workspace deleted", this);})
                .catch( () => {self.$root.failed("Unable to remove workspace", this)})
                
            }
        },
        changed(fields) {
            for (var k in fields) {
                this.settings[k] = fields[k];
            }
        },
        refreshModels() {
            if (!this.workspace) return;
            this.nameModel = this.workspace.name;
            this.envModel = this.workspace.environment;
            this.modeModel = this.workspace.mode; 
            this.metaModel = this.workspace.meta
        }
    },
    emits : ["saveWorkspace"],
    watch : {
        workspace() {
            this.refreshModels()
        }
    },
    async mounted() {
        this.refreshModels();
        let env = await Environments.getList();
        
        this.validEnvironments = []
        for (var i in env) {
            this.validEnvironments.push({
                label : env[i].title,
                value : env[i].key,
                icon : "/assets/tech/"+(env[i].icon32 || env[i].icon64)
            })
        }
    },
    setup() {
        const q = useQuasar();
        return {q}
    }
}
</script>

<style>

</style>