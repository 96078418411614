<template>
    <q-dialog v-model="visible" persistent @hide="onHide"
        >
        <edit-workspace-details
            :workspace="workspace"
            :editing="editing"
            @saveWorkspace="workspaceSaved"
            style="min-width: 600px"></edit-workspace-details>
    </q-dialog>
</template>

<script>
import Workspace from '@/workspace';
import { ref } from 'vue'

import EditWorkspaceDetails from './EditWorkspaceDetails';
export default {
    components : { EditWorkspaceDetails },
    props : {
        workspace : Object,
        showing : Boolean
    },
    watch : { showing() { this.visible = this.showing; },
        workspace() { this.editing = (this.workspace != null && this.workspace._id != undefined);} },
    data() {
        return {
            visible : false
        }
    },
    methods : {
        async workspaceSaved(data, settings) {
            
            
            if (!this.editing) this.createWorkspace(data.name, data.mode.value, data.environment.value);
            else {
                this.$emit("saveWorkspace", data, settings)
            }
            
        },
        onHide() {
            this.$emit("hide");
        },
        async createWorkspace(name, mode, environment) {
            const self = this;
            Workspace.createWorkspace(name, mode, environment)
                .then( async (ws) => {
                    
                    await Workspace.addDefaultWorkspaceFiles(ws)
                    
                    await Workspace.save(ws);

                    self.root.success("Your new workspace is ready",self)
                    this.$emit("hide");
                })
                .catch( (error) => {
                    var msg = error.msg || error
                    self.$root.failed(msg,self)
                })
        },
        renameWorkspace(newValue) {
            const self = this;
            if (this.workspace) {
                Workspace.renameWorkspace(this.workspace.name.toString(), newValue)
                    .then( () => {
                        self.$root.success("Workspace updated", self)
                        self.onHide();
                    })
                    .catch( (error) => {
                        console.log(this.workspace._rev)
                        self.$root.failed("Unable to update the workspace ("+error+")", self)
                        self.onHide();
                    })
                
            }
        }
    },
    mounted() {
        if (this.workspace && this.workspace._id)
            this.editing = true;
        else
            this.editing = false;
              
        
    },
    setup() {
        const editing = ref(false);
        
        return {editing}   
    }
}
</script>

<style>

</style>