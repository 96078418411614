
import Environment from '@/environments'
import Workspace from '@/workspace'
import jsonly from '@/lib/jsonly'
import Schema from '@/schema/challenge.schema.json'
const INSTRUCTIONS_FILENAME = "Instructions.md"
const INTRODUCTION_FILENAME = "Introduction.md"
const CHALLENGE_ID_LENGTH = 36
const CHALLENGE_NAME_MIN_LENGTH = 10
const CHALLENGE_INTRODUCTION_MIN_LENGTH = 30
const CHALLENGE_INSTRUCTIONS_MIN_LENGTH = 30
const CHALLENGE_UNIT_TESTS_MIN_LENGTH = 15
const CHALLENGE_MAX_DIFFICULTY = 5
const INVALID_WORKSPACE = 1
const INVALID_CHALLENGE_NAME = 2
const INVALID_WORKSPACE_MODE = 3
const INVALID_WORKSPACE_ENV = 4
const INTRODUCTION_TEXT_TOO_SHORT = 5
const INSTRUCTION_TEXT_TOO_SHORT = 6
const INVALID_WORKSPACE_NO_INTRODUCTION = 7
const INVALID_WORKSPACE_NO_INSTRUCTIONS = 8
const INVALID_WORKSPACE_CHALLENGE = 9
const INVALID_WORKSPACE_DIFFICULTY = 10

const CHALLENGE_MISSING_NAME = 1;
const CHALLENGE_MISSING_INTRODUCTION = 2;
const CHALLENGE_MISSING_INSTRUCTIONS = 3;
const CHALLENGE_MISSING_ENVIRONMENT = 4;
const CHALLENGE_MISSING_DIFFICULTY = 5;
const CHALLENGE_MISSING_START_CODE = 6;
const CHALLENGE_MISSING_UNIT_TESTS = 7;
const CHALLENGE_IS_NO_OBJECT = 8;
const CHALLENGE_MISSING_ID = 9;
const CHALLENGE_DIFFICULTY_INVALID = 10;

/*const CHALLENGE_STATE_NEW       = 0;
const CHALLENGE_STATE_PENDING   = 1;
const CHALLENGE_STATE_PUBLISHED = 2;
const CHALLENGE_STATE_HIDDEN    = 3;
const CHALLENGE_STATE_REJECTED  = 4;*/


const r = (code,message) => { return {
    code : code,
    msg : message
}};

function validateChallengeSchema(challenge)
{
    return jsonly.validate(challenge, Schema);
}

async function validateChallenge(challenge) 
{
    if (typeof challenge != "object") throw r(CHALLENGE_IS_NO_OBJECT, "Challenge has no name");
    if (typeof challenge.uuid != "string" || challenge.uuid.length != CHALLENGE_ID_LENGTH) throw r(CHALLENGE_MISSING_ID, "Challenge uuid is not 36 characters");
    if (typeof challenge.name != "string" || challenge.name.length < CHALLENGE_NAME_MIN_LENGTH) throw r(CHALLENGE_MISSING_NAME, "Challenge name is too short");
    if (typeof challenge.introduction != "string" || challenge.introduction.length < CHALLENGE_INTRODUCTION_MIN_LENGTH) throw r(CHALLENGE_MISSING_INTRODUCTION, "Challenge introduction is too short. Was "+challenge.introduction.length);
    if (typeof challenge.instructions != "string" || challenge.instructions.length < CHALLENGE_INSTRUCTIONS_MIN_LENGTH) throw r(CHALLENGE_MISSING_INSTRUCTIONS, "Challenge instruction is too short");
    if (typeof challenge.environment != "string" || Environment.get(challenge.environment) == null) throw r(CHALLENGE_MISSING_ENVIRONMENT, `Challenge environment '${challenge.environment}' does not exist`);
    if (typeof challenge.difficulty != "number" || challenge.difficulty < 0 || challenge.difficulty > CHALLENGE_MAX_DIFFICULTY) throw r(CHALLENGE_MISSING_DIFFICULTY, `Invalid difficulty '${challenge.difficulty}'`);
    if (typeof challenge.start_code != "object") throw r(CHALLENGE_MISSING_START_CODE, `Missing start code'`);
    if (typeof challenge.unit_tests != "string" || challenge.unit_tests.length < CHALLENGE_UNIT_TESTS_MIN_LENGTH) throw r(CHALLENGE_MISSING_UNIT_TESTS, `Missing unit tests or too short'`);
    if (typeof challenge.difficulty != "number" || challenge.difficulty < 1 || challenge.difficulty > 5) throw r(CHALLENGE_DIFFICULTY_INVALID, `Difficulty must be a number from 1 to 5`)
    if (typeof challenge.state != "number") challenge.state = 0;
    
    return true || validateChallengeSchema(challenge)
}

/**
 * 
 * @param {object} workspace 
 * @returns {boolean} True if validates, false otherwise
 */
async function validateWorkspaceToChallenge(workspace) {
    
    const validWorkspace = Workspace.validateWorkspace(workspace);
    if (validWorkspace !== true) {
        throw r(INVALID_WORKSPACE, "Not a valid workspace object")
    }
    if (typeof(workspace.challenge) !== "string" || workspace.challenge.length != 36) {
        throw r(INVALID_WORKSPACE_CHALLENGE, "Workspace must have a challenge id")
    }
    if (workspace.name.length < CHALLENGE_NAME_MIN_LENGTH) {
        throw r(INVALID_CHALLENGE_NAME, `Workspace name must be at least ${CHALLENGE_NAME_MIN_LENGTH} characters`)
    }
    if (workspace.mode != "challengeeditor") {
        throw r(INVALID_WORKSPACE_MODE, "Workspace must be a challenge editor")
    }
    const env = Environment.get(workspace.environment);
    if (env.modes.indexOf("challenge") === -1) {
        throw r(INVALID_WORKSPACE_ENV, "Workspaces targets environment that does not support challenges")
    }

    if (!Workspace.fileExists(INTRODUCTION_FILENAME, workspace)) 
        throw r(INVALID_WORKSPACE_NO_INTRODUCTION, `Workspace does not contain mandatory ${INSTRUCTIONS_FILENAME}`);

    if (Workspace.fileContent(null, INTRODUCTION_FILENAME, workspace).length < CHALLENGE_INTRODUCTION_MIN_LENGTH) {
        console.log(Workspace.fileContent(null, INTRODUCTION_FILENAME, workspace))
        console.error(Workspace.fileContent(null, INTRODUCTION_FILENAME, workspace).length)
        throw r(INTRODUCTION_TEXT_TOO_SHORT, `The introdution text should be longer than at least ${CHALLENGE_INTRODUCTION_MIN_LENGTH} charaters`)
    }

    if (!Workspace.fileExists(INSTRUCTIONS_FILENAME, workspace)) 
        throw r(INVALID_WORKSPACE_NO_INSTRUCTIONS, `Workspace does not contain mandatory ${INSTRUCTIONS_FILENAME}`);
    
    if (Workspace.fileContent(null, INSTRUCTIONS_FILENAME, workspace).length < CHALLENGE_INSTRUCTIONS_MIN_LENGTH)
        throw r(INSTRUCTION_TEXT_TOO_SHORT, `The introdution text should be longer than at least ${CHALLENGE_INSTRUCTIONS_MIN_LENGTH} charaters`)

    const level = Workspace.getSetting("level", workspace);
    
    if (typeof level != "number" || level < 1 || level > 5)
        throw r(INVALID_WORKSPACE_DIFFICULTY, `Workspace difficulty is an invalid type. Must be a number from 1 to 5. It's ${level} (${typeof level})`);
    
    return true;
}

/**
 * 
 * @param {*} workspace 
 * @returns {object} returns a workspace or null if it failed
 */
async function createChallengeFromWorkspace(workspace)
{
    await validateWorkspaceToChallenge(workspace).catch( (error => {
        throw "Cannot create challenge from workspace: "+error.msg;
    }))

    const c = {};
    const revision = Workspace.getSetting("challenge_revision", workspace) || false;
    c.name = workspace.name;
    c.environment = workspace.environment;
    c.difficulty = Workspace.getSetting("level", workspace) || 1
    if (revision) {
        c._rev = revision;
    }
    c.start_code = {}
    c.uuid = workspace.challenge;
    c.meta = {
        created : (new Date()).toISOString(),
        modified : "",
        author : workspace.meta.author,
        tags : []
    }
    
    const testfile = Environment.get(c.environment).testfile;
    for (var filename in workspace.files) {
        if (filename == "Introduction.md") {
            c.introduction = Workspace.fileContent(null, filename, workspace);
            continue;
        }
        if (filename == "Instructions.md") {
            c.instructions = Workspace.fileContent(null, filename, workspace);
            continue;
        }
        if (filename == testfile) {
            c.unit_tests = Workspace.fileContent(null, filename, workspace);
            continue;
        }

        c.start_code[filename] = Workspace.fileContent(null, filename, workspace);
    }
    
    const validates = await validateChallenge(c)
        .catch( (e) => {
            throw e;
        });
    if (validates)
        return c
    else
        return null;
}




export default { validateWorkspaceToChallenge, createChallengeFromWorkspace, validateChallenge }