import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const headerPaneStore = defineStore('headerPane', {
  state: () => {
    return { visible : true, buttons : [] }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    clear() {
      this.buttons = []
    },
    show() {
        this.visible = true;
    },
    hide() {
        this.visible = false;
    },
    add(title) {
        this.buttons.push( {
            title : title
        })
    }
  },
})
