let tests = {
    edit_submitted_challenge : require("@/tests/workflows/edit_submitted_challenge").default
}
let counter = 0
let success = 0
for (let name in tests) {
    for (let step in tests[name]) {
        let result = tests[name][step]();
        counter++;
        if (result) {
            success++;
        }
    }
}

console.log(`${success} out of ${counter} workflow tests succeeded`);
