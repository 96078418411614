<template>
<q-toolbar>
  <q-btn-group push>
    <q-btn padding="4px 4px" outline color="blue-grey-7"  icon="note_add" @click="addFile" ></q-btn>
    <q-btn padding="2px 6px" v-for="s, filename in sessions" :key="filename" @click="setFile(filename.toString())">{{filename}}</q-btn>
  </q-btn-group>
  <q-space ></q-space>
  <q-btn v-if="!overlayAddFile" padding="4px 4px" outline color="red" icon="delete" style="float:right" :disable="loading" @click="deleteMessage='Really delete '+currentFile+'?'" />  
</q-toolbar>
<q-card flat bordered class="my-card">
  <v-ace-editor
    @init="editorInit"
    theme="ambiance"
    style="height: 300px"
    :value="content"
    />
    <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="blue-grey-7" />
      </q-inner-loading>
      <q-inner-loading :showing="overlayAddFile" @click="addFile" style="cursor:pointer;">
        <q-icon name="note_add" color="blue-grey-7" size="50px"></q-icon>
      </q-inner-loading>

    </q-card><br>
    <q-btn class="bg-blue-grey-7" style="float:right" :disable="loading" :loading="running" label="Check" @click="evaluate" />
    
    <q-btn label="A-" @click="decFont" />
    <q-btn label="A+" @click="incFont" />
    

  <code-add-file-dialog :show="showAddFile" @create="createFile" @hide="showAddFile=false"></code-add-file-dialog>
  <!--<confirm-box :visible="showConfirmDelete" @confirm="confirmDelete" :label="'Delete'" :message="'Really delete '+currentFile+'?'"></confirm-box>-->

  <confirm-box @confirm="deleteConfirmation" :message="deleteMessage" :label="'Delete file'"></confirm-box>
  
</template>

<script>
import { VAceEditor } from 'vue3-ace-editor'

import ace from 'ace-builds/src-noconflict/ace'
import workspace from '@/workspace'
window.define = ace.define
window.require = ace.require
//import workerJsonUrl from 'file-loader?esModule=false!ace-builds/src-noconflict/mode-csharp.js'; // For webpack / vue-cli
//console.log(workerJsonUrl)*/
//ace.config.setModuleUrl('/assets/',workerJsonUrl);
ace.config.set("basePath", "/");
//import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import 'ace-builds/src-noconflict/theme-ambiance';


import {evaluate} from '@/services'
import CodeAddFileDialog from './code/CodeAddFileDialog.vue'
import ConfirmBox from './ConfirmBox.vue'
import Environment from '@/environments'
import { MessageBox } from '@/utils'
import { useQuasar } from 'quasar'
const RUN_KEY = "F5";
const AUTOSAVE_DELAY = 1000;

const envToMode = {
  'dotnet6' : "csharp",
  'python3' : "python",
  "rust" : "rust",
  "php" : "php",
  'markdown' : 'markdown'
}

export default {
  name: 'CodeEditor',
  components: {
    VAceEditor,CodeAddFileDialog,
    ConfirmBox
  },
  props: {
    mode : String,
    challenge: Object,
    workspace : Object,
    environment: String,
    options : Object,
  },
  data() {
    return {
      editor : null,
      fontsize : 16,
      content : "",
      loading : true,
      overlayAddFile : false,
      output : "No output yet, try code",
      sessions : {},
      saveTimeout : null,
      running: false,
      showAddFile : false,
      currentFile : "",
      deleteMessage : "",
      q : useQuasar()
    }
  },
  watch : {
    code() {
      this.content = this.code
      //doesn't work
      this.editor.moveCursorToPosition({ row : 5, col : 5})
    },
    workspace() {
      this.updateWorkspace();    
    }
  },
  emits : [
    "runState"
  ],
  methods : {
  
    editorInit(ed) {
      var self = this;

      this.editor = ed;
      document.addEventListener("keydown",(event) => {
        self.editor.isFocused()
        
        if (event.key == RUN_KEY && self.editor.isFocused()) {
          self.evaluate();
          return false;
        }
      });
      ed.setFontSize(this.fontsize)
      //Autosave 
      ed.on("change", () => {
        clearTimeout(self.saveTimeout)
        self.saveTimeout = setTimeout(() => {
          self.saveWorkspace();
        },AUTOSAVE_DELAY)
      }); 
    },
    updateWorkspace() {
      this.sessions = {}
      let fileLoaded = false;
      console.log("Update workspace")
      if (this.workspace) {
        for (let file in this.workspace.files) {
          console.log(this.workspace.files[file].type)
          var mode = "ace/mode/" + (envToMode[this.workspace.files[file].type] || "text");
          
          this.sessions[file] = ace.createEditSession(this.workspace.files[file].content, mode);
          
          if (this.workspace.currentFile == file) {
            this.setFile(file);
            fileLoaded = true; 
          }
        }

        if (!fileLoaded) {
          for (let file in this.workspace.files) {
            this.setFile(file)
            fileLoaded = true
          }
        }

        this.loading = false;
        if (!fileLoaded) {

          this.overlayAddFile = true;
          this.editor.setValue("");
        }
      }
    },
    async saveWorkspace() {
      workspace.fileContent(this.editor.getValue());
      await workspace.save();
    },

    setFile(filename) {
      if (this.sessions[filename] != undefined) {
        this.editor.setSession(this.sessions[filename]);
        this.currentFile = filename;
        this.overlayAddFile = false;
        if (workspace.current() !== null)
          workspace.current().currentFile = filename;
      }
    },

    evaluate() {
      this.$emit("runState",1);
      this.running = true;
      var self = this;
      this.output = "Running...";
      var request = {
        environment : this.environment || this.workspace.environment,
        mode : this.mode,
        code : {}
      }
      for (var file in this.sessions) {
        request.code[file] = this.sessions[file].toString();
      }
    
      if (this.workspace.challenge) {
        request.challenge =  this.workspace.challenge.toString();
        console.log(this.workspace.challenge.toString())
      }
      if (this.options && this.options.testfile) {
        request.testfile = this.options.testfile;
      }

      evaluate.post("/evaluate", request
      ).then( result => {
        this.running = false;
        
        if (result.status == 200) {
          if (result.data.error) {
            self.output = "Failed: "+result.data.output;
            this.$emit("runState", 0, result.data.output);
          } else {
            this.$emit("runState", 2, result.data.output);
            self.output = result.data.output;
          }
          
        }
      }).catch( err => {
        this.running = false;
        console.log(err);
        this.$emit("runState", 0, err);
        self.output = "Failed: "+err
      });
    },
    incFont () { this.fontsize++; this.editor.setFontSize(this.fontsize); },
    decFont () { this.fontsize--; this.editor.setFontSize(this.fontsize); },
    addFile() {
      this.showAddFile = true;
    },
    createFile(filename) {
      const type = Environment.fileExt2Env(filename);
      workspace.createFile(filename, type, this.workspace);
      this.setFile(filename);
      this.updateWorkspace();
      this.overlayAddFile = false;
    },
    deleteConfirmation(doDelete) {
      if (!doDelete) {
        this.deleteMessage = '';
        return;
      }

      if (this.mode == "challengeeditor") {
        const forbidden = ["Introduction.md", "Instructions.md", Environment.get(this.workspace.environment).testfile];

        if (this.currentFile && forbidden.indexOf(this.currentFile) !== -1) {
          MessageBox.warning("Cannot remove this file. It is a required part of the challenge.", this);
          return false;
        }
      }

      if (workspace.removeFile(this.currentFile))
      {
        this.editor.setValue("");
        this.$q.notify({
              spinner: false,
              icon: 'done',
              message: 'Deleted '+this.currentFile
          })
          workspace.save();
          this.updateWorkspace()
      } else {
        this.$q.notify({
              spinner: false,
              icon: 'error',
              message: "Couldn't delete the file"
          })
      }
    }
  },
  mounted() {
    this.loading = true;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
