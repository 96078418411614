<template>
  <div class="q-pa-md row items-start q-gutter-md justify-start">
      <div class="col-3">
        Filter
      </div>
      <div class="col-8 items-start q-gutter-md">
        <div class="text-h6">Create User</div>
        <q-card-section  class="challenge-card row justify-between">
            <q-card-section class="col-md-6">
                <user-form @save="onSave"></user-form>
            </q-card-section>
        </q-card-section>
    </div>
  </div>
</template>

<script>
import UserForm from '@/components/UserCreateForm.vue';
import {evaluate} from '@/services'
//import { Console } from 'console';
export default {
components : {
    UserForm
},
  methods : {
      onSave(data) {
          var url = "/users/create";

      var msg = this.$q.notify({
        group: false, // required to be updatable
        spinner: true,
        message: 'Creating user'
      })
      console.log(data);
      evaluate.put(url, {
        "username" : data.username,
        "password" : data.password
      })
        .then( () => {
            msg({
            color: 'green-4',
            textColor: 'white',
            spinner: false,
            icon: 'thumb_up',
            message: "Welcome aboard!",
          })
          
        })
        .catch( () => {
          msg({
            color: 'red-5',
            textColor: 'white',
            spinner: false,
            icon: 'warning',
            message: 'Oh no. This went south.'
          })
        });
      }
  },

  setup() {

  }
}
</script>

<style>

</style>