<template>
  <q-header class="bg-blue-grey-7">
    <q-toolbar>
        <q-btn
        flat
        dense
        round
        @click="$emit('toggleLeftPane')"
        aria-label="Menu"
        icon="menu"
        />
        <q-toolbar-title>
        <span class="cf-title" @click="go('/sandbox')">CodeFreek</span>
        </q-toolbar-title>
        <q-space ></q-space>
        <q-btn v-if="signedIn" flat round dense icon="person" @click="go('/user/profile')" />
        <q-btn v-else flat @click="go('/user/signin')">Sign in</q-btn>
        <q-btn v-if="signedIn" @click="$emit('signout')" flat round dense icon="logout" />
        <q-btn v-else flat  @click="go('/user/register')">Register</q-btn>
    </q-toolbar>
    <q-tabs align="left">
        <q-btn-dropdown v-if="headerPane.buttons.length >0" class="bg-blue-grey-7" flat label="Choose language">
          <q-list>
            <q-item clickable v-close-popup @click="onItemClick"
                    v-for="tech in headerPane.buttons"
                    :key="tech">
              <q-item-section>
                <q-item-label>{{tech.title}}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <!--<q-route-tab to="/instructions" label="Instructions" />
        <q-route-tab to="/code" label="Code" />-->
      </q-tabs>
  </q-header>
</template>

<script>
//import { ref } from "vue";
import { headerPaneStore } from '@/stores/header'
import router from '@/router'

export default {
  props : {
    signedIn : Boolean
  },
    methods : {
      go(url) { router.push(url); }
    },

    setup() {
        const headerPane = headerPaneStore()

        return { headerPane }
    }
}
</script>

<style>
.cf-title {
  cursor: pointer;

}
</style>