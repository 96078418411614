/*
A workspace is a collection of files solving a specific problem.
User cannot edit a file without a workspace. A workspace is usually
created when the user visits a new environment in sandbox mode or
starts a challenge.
A list of workspaces can be seen on the users profile page. From
here the user can resume work by clicking a workspace.
A workspace can be renamed and remove. The user can also start a
new sandbox workspace by selecting an environment.
*/
import DB from '@/db'
import Environment from '@/environments'
import { createUUID } from './utils';
//import { createUUID } from '@/utils'

let current_workspace = null;
let workspaceTemplate = {
    _id : "",
    name : "",
    mode : "",
    environment : "",
    challenge : "", //Required UUID if mode is challenge
    currentFile : "",
    files : {},
    settings : {},
    meta : {
        created : "",
        modified : "",
        author : ""
    }
}



function t(c,m) { return {err: c, msg: m}}
function docId(name) { return `workspace:${name}`}
function validateWorkspace(workspace) {
    if (typeof(workspace.name) != "string" || workspace.name.length == 0)
        throw t(1,"Missing workspace.name");

    if (typeof(workspace.mode) != "string" || workspace.mode.length == 0)
        throw t(2,"Missing workspace.mode");
    
    if (["sandbox", "challenge", "challengeeditor"].indexOf(workspace.mode) === -1) {
        throw t(3,`Invalid workspace.mode ${workspace.mode}`);
    }

    if (typeof(workspace.environment) != "string" || workspace.environment.length == 0)
        throw t(4,"Missing workspace.environment");
    if (Environment.getKeys().indexOf(workspace.environment) === -1) {
        throw t(5,`Invalid workspace.environment ${workspace.environment}`);
    }

    if (workspace.mode == "challenge" && (
        typeof(workspace.challenge) != "string" || workspace.challenge.length != 36)) {
            throw t(6,`Missing valid workspace.challenge`);
    }
    //if (typeof(workspace.workingfile) != "string" || workspace.workingfile.length == 0)
        //throw t(7,"Missing workspace.workingfile");

    if (typeof(workspace.files) === "undefined")
        workspace.files = {}
    
    for (let filename in workspace.files) {
        if (typeof(workspace.files[filename].content) === "undefined") {
            throw t(8,`File ${filename} has no content`)
        }
        if (typeof(workspace.files[filename].type) === "undefined") {
            throw t(9,`File ${filename} has no type`)
        }
    }

    if (typeof(workspace.workingfile) == "string") {
        let exists = false;

        for (let filename in workspace.files) {
            if (filename == workspace.workingfile) {
                exists = true;
                break;
            }
        }
        if (exists === false) {
            throw t(10,`The workingfile '${workspace.workingfile}' does not exists in workspace.files`)
        }
    }

    return true;
}
/**
 * 
 * @param {string} name Name of workspace
 * @param {string} mode What mode (ie. sandbox or challenge)
 * @param {string} environment The intended environment
 * @param {object} options An object of options. {
 *   "challenge" : uuid of a challenge
 *   "saveNow" : true if workspace is supposed to be saved immediately.
 *   "temporary" : false if the workspace is ment to be temporary
 * }
 * @returns 
 */
async function createWorkspace(name, mode, environment, options)
{
    let ws = {}
    for (var k in workspaceTemplate) {
        ws[k] = workspaceTemplate[k];
    } 
    ws.name = name;
    ws.mode = mode;
    ws.environment = environment;
    ws.files = {};
    ws.meta.created = (new Date()).toISOString();
    
    if (options != undefined) {
        if (options.challenge != undefined) {
            ws.challenge = options.challenge;
        }
    }

    if (validateWorkspace(ws)) {
        ws._id = docId(ws.name);

        if (options && options.saveNow === true)
        {
            await DB.put(ws).catch( e => {throw e});
            return await DB.get(ws._id)
        }
        return ws;
    }
    return null;
}

async function removeWorkspace(name)
{
    var doc = await DB.get(docId(name)).catch( e => {
        throw e;
    })
    if (doc) {
        let result = await DB.remove(doc).catch( (e) => {
            throw e
        });
        //console.log(name+" == "+current_workspace)
        if (current_workspace != null && name == current_workspace.name) {
            current_workspace = null;
        }
        return result;
    }
    return null;
}

async function createDefaultWorkspace(environment) {
    
    let ws = await createWorkspace("Default "+environment+" workspace", "sandbox", environment).catch( e => { 
        throw e;
    });

    addDefaultWorkspaceFiles(ws, environment, "sandbox")
    return ws;
}

async function addDefaultWorkspaceFiles(workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    const environment = workspace.environment
    const mode = workspace.mode;

    let files = Environment.getStartingFiles(environment, mode);
    for (let i in files) {
        createFile(files[i].filename, environment, workspace);
        workspace.files[files[i].filename].content = files[i].content;
    }

    if (mode == "challengeeditor") {
        //Set a challenge uuid
        workspace.challenge = createUUID()
        setSetting("level", 1, workspace)
        //Also create test file
        const test_filename = Environment.get(environment).testfile
        if (!test_filename) throw(`Missing testfile property in ${environment}`)
    }

    await DB.put(workspace).catch( e => {throw e});

    return await DB.get(workspace._id)
}

async function getMyWorkspaces() {
    let result = await DB.allDocs({
        include_docs: true,
        attachments: true,
        start_key: 'workspace:',
        endkey: 'workspace:\ufff0'
    })


    if (result)
        return result.rows
}

async function load(name) {
    name = (name.indexOf("workspace:") === 0) ? name.substr(10) : name;
    var result = await DB.get(docId(name))
        .catch( e => {
            throw e;
        });
    current_workspace = result;
    if (typeof(current_workspace.settings) == "undefined")
        current_workspace.settings = {}
    if (typeof(current_workspace.meta) == "undefined")
        current_workspace.meta = {
            created : 0,
            modified : 0,
            author : "",
        }

    //Check that currentFile exists
    if (result.currentFile) {
        if (!fileExists(result.currentFile,current_workspace)) {
            current_workspace.currentFile = "";
        }
    }
    for (let file in current_workspace.files) {
        current_workspace.currentFile = file;
        break;
    }

    localStorage.setItem(`last:${current_workspace.environment}:${current_workspace.mode}`, name);
    return current_workspace;
}

async function loadLastWorkspace(environment, mode) {
    if (!environment) throw "Missing environment";
    if (!mode) throw "Missing mode";
    var last_workspace = localStorage.getItem(`last:${environment}:${mode}`);
    if (last_workspace) {
        await load(last_workspace).catch( (e) => {
            throw e
        });
        return true;
    }
    return false;
}

async function save(workspace) {
    if (typeof(workspace) === "undefined") {
        workspace = current_workspace
    }

    const response = await DB.put(workspace);
    workspace._rev = response.rev;
    
}

async function renameWorkspace(fromName, toName) {
    
    
    var doc = await DB.get(docId(fromName)).catch();
    
    if (doc) {
        doc._id = docId(toName);
        doc.name = toName;
        delete doc._rev;
        await DB.put(doc).catch( (e) => { throw "Unable to rename: "+e});
        await removeWorkspace(fromName);
    }
/*
    
    var tmp = current_workspace;
    await load(fromName);
    current_workspace._id = docId(toName);
    delete current_workspace._rev;
    await DB.put(current_workspace);
    await removeWorkspace(fromName)

    current_workspace = tmp;*/
}

function current() {
   
    return current_workspace;
}

/**
 * 
 * @param {string} filename The name of the file
 * @param {object} workspace Optional. If omitted file will be added to current workspace
 */
function fileExists(filename, workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    if (typeof(workspace.files) == undefined) 
        return false;
    return typeof(workspace.files[filename]) === "object"
}


/**
 * Creates a new file within the workspace
 * @param {string} filename The name of the file
 * @param {string} type The environment of the file
 * @param {object} workspace Optional. If omitted file will be added to current workspace
 */
function createFile(filename, type, workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    if (!workspace) {
        return false;
    }
    if (typeof filename != "string")
        throw "First argument of createFile 'type' is not a string"
    if (typeof type != "string") {
        throw "Second argument of createFile 'type' has is invalid ("+(typeof type)+")"
    }
    let re_valid_filename = /^[0-9a-zA-Z^&'@{}[\],$=!-#().%+~_ ]+$/i
    var matches = filename.match(re_valid_filename)
    if (!matches || matches[0] != filename)
        throw "Invalid filename"

    if (fileExists(filename, workspace))
        return false;

    workspace.files[filename] = {
        type : type,
        content : ""
    }
    workspace.currentFile = filename;
    return true;
}
/**
 * Removes a file within the workspace
 * @param {string} filename  The name of the file
 * @param {object} workspace Optional. If omitted file will be added to current workspace
 * @returns {boolean} true if deleted. False otherwise.
 */
function removeFile(filename, workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    if (workspace.files[filename]) {
        delete workspace.files[filename];
        return true;
    }
    return false;
}
/**
 * 
 * @param {string} filename  The name of the file
 * @param {object} workspace Optional. If omitted file will be added to current workspace 
 */
function renameFile(fromName, toName, workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    if (workspace.files[fromName]) {
        workspace.files[toName] = workspace.files[fromName]
        delete workspace.files[fromName];
        return true;
    }
    return false;
}

function currentFile(workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    if (!workspace.currentFile) {
        for (var filename in current_workspace.files) {
            current_workspace.currentFile = filename;
            break;
        }
    }
    if (!workspace.currentFile) {
        throw "No files in workspace";
    }
    return current_workspace.files[current_workspace.currentFile];
}
/**
 * 
 * @param {string} content Optional setter. If undefined the content will not be updated
 * @param {string} filename Optional the file in the workspace
 * @param {object} workspace Optional the workspace of the file
 */
function fileContent(content, filename, workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    if (filename == undefined) {
        filename = current_workspace.currentFile;
    }
    try {
        if (typeof(content) == "string") {
            workspace.files[filename].content = content;
        }
        return workspace.files[filename].content
    } catch {
        throw `File ${filename} does not exist in workspace ${workspace.name}`;
    }
}

function getSetting(key, workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    if (typeof(workspace.settings) === "object"
        && typeof(workspace.settings[key]) !== "undefined") {
            return workspace.settings[key];

    }
    return null;
}
function setSetting(key, value, workspace) {
    if (workspace == undefined) {
        workspace = current_workspace;
    }
    if (typeof(workspace.settings) !== "object")
        workspace.settings = {}
    if (value == undefined && typeof(workspace.settings[key]) !== "undefined")
        delete workspace.settings[key];
    else
        workspace.settings[key] = value;
}

async function constructWorkspaceFromChallenge(challenge, workspace_options) 
{
    const Challenge = require("./challenges").default;
    const valid_challenge = await Challenge.validateChallenge(challenge);
    if (valid_challenge !== true) return null;
    if (typeof(workspace_options) !== "object")
        workspace_options = {}
    workspace_options.challenge = challenge.uuid;

    var challenge_name = workspace_options.temporary ? `Editing remote ${challenge.name}` : challenge.name;

    const workspace = await createWorkspace(challenge_name, "challengeeditor", challenge.environment, workspace_options)
    createFile("Introduction.md", "markdown", workspace)
    fileContent(challenge.introduction, "Introduction.md", workspace)

    createFile("Instructions.md", "markdown", workspace)
    fileContent(challenge.instructions, "Instructions.md", workspace)

    const test_filename = Environment.get(challenge.environment).testfile;
    createFile (test_filename, challenge.environment, workspace);
    fileContent(challenge.unit_tests, test_filename, workspace);

    for (var filename in challenge.start_code)
    {
        createFile(filename, challenge.environment, workspace)
        fileContent(challenge.start_code[filename], filename, workspace);
    }

    setSetting("level", challenge.difficulty, workspace)
    if (challenge._rev)
        setSetting("challenge_revision", challenge._rev, workspace)

    workspace.meta.author = challenge.meta.author
    workspace.meta.modified = challenge.meta.modified
    workspace.meta.created = challenge.meta.created

    if (validateWorkspace(workspace)) 
        return workspace
    return null;
}
function mergeWorkspaceVersions(source, destination, force) {
    force = force === true
    if (source._id !== destination._id && !force) {
        throw "Cannot merge workspaces. Document id mismatch"
    }
    if (source._rev !== undefined)
        destination._rev = source._rev


    if (source.environment != destination.environment && !force)
        throw "Cannot merge workspaces. Environments does not match"
    
    if (source.mode != destination.mode)
        throw "Cannot merge workspaces. Modes does not match"

    destination.name            = source.name
    destination.mode            = source.mode
    destination.environment     = source.environment
    destination.challenge       = source.challenge
    destination.files           = source.files
    destination.settings        = source.settings
    destination.meta            = source.meta
}


export default { validateWorkspace, createWorkspace, removeWorkspace, createDefaultWorkspace, 
    addDefaultWorkspaceFiles, getMyWorkspaces, renameWorkspace, load, current, save, 
    createFile, removeFile, renameFile, currentFile, fileContent, fileExists,
    loadLastWorkspace, constructWorkspaceFromChallenge, getSetting, setSetting, mergeWorkspaceVersions }
