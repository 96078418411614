<template>
  <code-tabs></code-tabs>
  <div class="q-pa-md row items-start q-gutter-md">
      <div class="col">
        <code-editor
          @runState="onRunStateChanged" 
          :workspace="workspace" 
          :mode="'sandbox'" 
          :environment="environment"></code-editor>
      </div>
      <div class="col">
        <code-output :output="output" :state="state"></code-output>
    </div>
  </div>
  
</template>

<script>
import CodeEditor from "@/components/CodeEditor.vue"
import CodeTabs from "@/components/CodeTabs.vue"
import CodeOutput from "@/components/CodeOutput.vue"
import workspace from '../workspace'
import Environment from '@/environments'
const MODE = "sandbox";

export default {
  components : {
    CodeTabs,
    CodeEditor,CodeOutput
  },
  data() {
    return {
      output : "",
      state : -1,
      code : "",
      workspace : null,
      environment : "",
    }
  },
  methods : 
  {
    onRunStateChanged(state, output) {
      this.state = state;
      switch (state) 
      {
        case 0: 
          this.output = "Error: "+output;
          break;
        case 1:
          //this.output = "Running";
          break;
        case 2: 
          this.output = output;
          break;
      }
    }
  },
  
  async mounted() {
    let lastLoaded = false;
    lastLoaded = await workspace.loadLastWorkspace(this.environment, MODE)
      .catch(async () => {
        lastLoaded = false
        
      });
    
    if (!lastLoaded) {
      var ws = null;
      ws = await workspace.createDefaultWorkspace(this.environment).catch( async e => {
        switch (e.status) {
          case 409:
            await workspace.load(e.docId)
            break;
        }
      });
      
      if (ws) {
        await workspace.load(ws.name)
      }
      
    }
    
    if (ws == undefined) {
      ws = workspace.current();
    }
    this.workspace = ws;
    
  },
  created() {
    var self = this;
    this.$watch(
      () => this.$route.params,
      (toParams) => {
        if (Environment.exists(toParams))
          self.environment = toParams;
      }
    )
    this.environment = this.$route.params.environment
  }
}
</script>

<style>

</style>