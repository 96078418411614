<template>
  <q-form ref="form"
     @submit="onSubmit"
     @reset="onReset">
     <q-input
        filled
        v-model="name"
        label="Username *"
        hint="The username associated with your account"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      /><br>

      <q-input
        filled
        v-model="pwd1"
        label="Password *"
        hint="A fancy password"
        type="password"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      /><br>

      <q-input
        filled
        v-model="pwd2"
        label="Repeat password"
        type="password"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something', val => val == pwd1 || 'Passwords do not match' ]"
      /><br>
      <div>
        <q-btn label="Submit" type="submit" color="primary" />
        <q-btn label="Reset" type="reset" color="primary" flat class="q-ml-sm"/>
      </div>
  </q-form>
</template>

<script>
import { ref } from 'vue'
import { sha256 } from '@/util/digest'
import { useQuasar } from 'quasar'
export default {
  props : {
    "user_name" : String,
    "user_id" : String,
    "user_pwd" : String
  },
  methods : {

    resetform() {
      this.name = this.user_name;
      /*pwd1.value = user_id;
      pwd1.value = user_pwd;
      pwd2.value = user_pwd*/
    },
    async onSubmit () {
      if (this.pwd1 != this.pwd2) throw "Passwords are not equal";
      var hashed = await sha256(this.pwd1+this.name.toString()+document.location.hostname);
    
      this.$emit("save", {
        "username" : this.name,
        "password" : hashed
      })
    }
  },
  mounted() {
    this.resetform();
  },

  setup() {
    const $q = useQuasar();
    const form = ref(null);
    const name = ref("");
    const pwd1 = ref("");
    const pwd2 = ref("");

    function validate () {
      form.value.validate().then(success => {
        if (success) {
          console.log("alraight");
          // yay, models are correct
        }
        else {
          console.error("Bummer")
          // oh no, user has filled in
          // at least one invalid value
        }
      })
    }
    
    return { name, pwd1, pwd2, validate, $q }
  }
}
</script>

<style>

</style>